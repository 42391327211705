import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Container, Grid, } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { userService } from "../userService";
import { disableSite } from "../features/actions/siteAction";
import { FiPlus } from "react-icons/fi"
import { DataStatus } from "../CommonComps/DataStatus";
import { AlertBar } from "../Alert/AlertBar";
import { themecolor } from "../Navigation";
import { voucherTypeSaveUrl, voucherTypesUrl } from "../config";
import LoadingComponent from "../Loader";
import Header from "../CommonComps/Header";
import IconTemplate from "../CommonComps/IconTemplate";
import { styles } from "../Theme/Theme";
import TemplateTable from "./TemplateTable";
import GroupModal from "./GroupModal";
import { checkPathExist, checkPermissionExist } from "../utils";
const customFieldIcon = require("../images/custom.png")

const Index = ({ classes }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const reduxState = useSelector(val => val?.rootReducer)
    const site = reduxState?.siteReducer;
    const endPoints = reduxState?.permissionReducer?.data

    // =================States=================//
    const [loading, setLoading] = useState(false)
    const [vouchersList, setVoucherList] = useState([])
    const [visible, setVisibility] = useState(false);
    const [alertMessage, setMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [open, setOpen] = useState(false)
    const [groups, setGroups] = useState([{
        list: [], type: null
    }])

    const closeAlertBar = () => setVisibility(false);
    const closeDialogBox = () => setOpen(false);

    // Function  to show success alert message
    const successAlert = (message) => {
        setVisibility(true);
        setAlertType("success");
        setMessage(message || "Data fetched succesfully");
        setLoading(false);
    };

    // Function  to show error alert message
    const errorAlert = (message) => {
        setVisibility(true);
        setAlertType("error");
        setMessage(message || "Something went wrong");
        setLoading(false);
    };

    const GetVoucherTemplates = async () => {
        const pathExist = checkPathExist(endPoints, window.location.pathname)
        if (!pathExist) return
        setLoading(true)
        try {
            const payload = {
                companyId: localStorage.getItem("companyId"),
                siteId: localStorage.getItem("siteId")
            }
            const res = await userService.postData(voucherTypesUrl, payload)

            if (res && res.data && res.data.status === 200) {

                if (res.data.data && res.data.data.length > 0) {

                    setVoucherList(res.data.data)
                    successAlert(res?.data?.message)
                }
                else {
                    setVoucherList([])
                    successAlert("No data available right now.")
                }

            }
            else errorAlert(res?.data?.message || "Unable to fetch list at this mommet")
        } catch (error) {
            errorAlert("Something went wrong")
        }
        finally{
            setLoading(false)
        }
    }

    useEffect(() => {
        dispatch(disableSite(false))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const permissionExist = checkPermissionExist(endPoints, voucherTypesUrl)
        if (permissionExist) site && GetVoucherTemplates()

        else errorAlert("Unautharized to access voucher template list.")

        // eslint-disable-next-line
    }, [site])


    useEffect(() => {
        const pathExist = checkPathExist(endPoints, window.location.pathname)
        if (!pathExist) navigate("/error")
    
    }, [endPoints])

    const iconTemplateArray = [
        {
            name: "Create Template",
            icon: <FiPlus />,
            function: () => navigate("/createVoucherTemp"),
            style: {
                background: `${themecolor}`, color: "#fff", marginLeft: 10,
                display: checkPermissionExist(endPoints, voucherTypeSaveUrl) ? "inline-flex" : "none"
            }
        },
    ]

    const handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === "Insert" && !e.altKey && !e.shiftKey) {
            if (
                checkPermissionExist(endPoints, voucherTypeSaveUrl)
            ) {
              navigate("/createVoucherTemp")
            }else{
              errorAlert("You don't have permission to Create Voucher!!");
            }
          }
    
      };
    
      useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
    
        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        };
      }, [handleKeyDown]);


    return (
        <>

            <Paper className={classes.paper} elevation={0} style={{ width: '100%', overflow: 'auto', }}>
                <Container style={{ maxWidth: "100%" }}>
                    <Grid container style={{ margin: "0", alignItems: "center" }}>
                        <Header
                            gridSize="3"
                            source={customFieldIcon}
                            headerText="Voucher Templates"
                        />
                        <Grid item xs={9} spacing={3} alignItems="center" container justifyContent="flex-end">

                            {
                                iconTemplateArray && iconTemplateArray.length > 0 &&
                                <IconTemplate
                                    data={iconTemplateArray}
                                />
                            }
                        </Grid>
                    </Grid>
                    {
                        vouchersList && Array.isArray(vouchersList) && vouchersList.length > 0 ?
                            <TemplateTable data={vouchersList}
                                setOpen={setOpen}
                                setGroups={setGroups}
                                endPoints={endPoints}
                                errorAlert={errorAlert}

                            />
                            : <DataStatus />
                    }

                </Container>
            </Paper>


            {/* ==============Alerts=============== */}
            <AlertBar
                visible={visible}
                closeAlertBar={closeAlertBar}
                alertMessage={alertMessage}
                alertType={alertType}
            />


            {/* =============Loader=============== */}
            {loading && <LoadingComponent />}

            <GroupModal
                open={open}
                groups={groups}
                closeDialogBox={closeDialogBox}
            />
        </>
    );
};

export default withStyles(styles)(Index);
