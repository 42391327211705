import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Paper,
  IconButton,
  TableRow,
  TableCell,
  Button,
  Divider,
  Tooltip,
  Checkbox,
  Collapse,
  Box,
} from "@mui/material";
import { FcInfo } from "react-icons/fc";
import { TiInfoLarge } from "react-icons/ti";
import { headers } from "./util";
import { withStyles } from "@material-ui/core";
import { Editedtext } from "../CommonComps/TextTemplate";
import { DataStatus } from "../CommonComps/DataStatus";
import { pageSizes } from "../Account/util";
// import { StyledTableCell, StyledTableRow } from "../utils/index";
import { getEncryptPayload } from "../Network_Utility";
import { EditedtextCode } from "../CommonComps/TextTemplate";
import CommonPagination from "../CommonComps/CommonPagination";
import moment from "moment";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import InfoIcon from "@mui/icons-material/Info";
import Swal from "sweetalert2";
import { BsCheckLg } from "react-icons/bs";
import { themecolor } from "../Navigation";
import Modal from "@mui/material/Modal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { FaHistory } from "react-icons/fa";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { checkModuleExist, checkPathExist, checkPermissionExist } from "../utils";
import { singleTransactionGetUrl } from "../config";
import { useSelector } from "react-redux";
import { NumberFormatter } from "../CommonComps/NumberFormatter";
import ConvertAmountToWords from "../CommonComps/AmountConverter";
import { Info } from "@mui/icons-material";
import AmountInfo from "../CommonComps/AmountInfo";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: ` ${themecolor} !important`,
    padding: "10px 5px !important",
    color: "white !important",
    fontSize: "15px !important",
    // width: "fit-content !important"
  },
  body: {
    fontSize: 13,
    fontWeight: 400,
    padding: "10px 5px!important",
    borderBottom: "none",
    border: "none !important",
    color: "#555",
    // width: "fit-content !important"
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
  },
}))(TableRow);

function Row(props) {
  const {
    row,
    index,
    handleRevert,
    site,
    getSameValue,
    parentEle,
    spanRw,
    count,
    voucherList,
    nextActionList,
    selectedStatus,
    filterStateList,
    handleCheckboxChange,
    path,
    parentInd,
    isSelected,
    SelectedTransactionIds,
    headers,
    showAllTransaction,
    filteredColumns,
  } = props;

  const endPoints = useSelector(
    (val) => val?.rootReducer?.permissionReducer?.data
  );
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [images, setImages] = useState([]);
  const [action, setAction] = useState(null);
  const [open, setOpen] = useState(false);

  const openModal = (action) => {
    setAction(action);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleImageUpload = (event) => {
    const uploadedImages = event.target.files;
    setImages(uploadedImages);
  };

  const navigate = useNavigate();

  const revertTransaction = async (row) => {
    const result = await Swal.fire({
      title: "Are you sure you want to revert this transaction?",
      text: `Transaction Id: ${row.txnId}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    });

    if (result.isConfirmed) {
      const payload = {
        remarks: `Reverting Transaction number ${row.txnId}`,
        siteId: localStorage.getItem("siteId"),
        txnId: row.txnId,
      };

      const encryptedPayload = getEncryptPayload(payload, true);

      handleRevert(encryptedPayload);
    }
  };

  const statusName =
    nextActionList &&
    nextActionList?.filter((val) => val.stateId == row.permissionStateId);

  const handleClickIcon = (rowData) => {
    if (rowData) {
      setOpen(!open);
    } else {
      setOpen(!open);
    }
  };

  return (
    <React.Fragment key={index}>
      <StyledTableRow
        style={{
          backgroundColor: getSameValue.color,
          pointerEvents:
            row?.balanceProcessStatus === "Processed" ? "all" : "none",
        }}
      >
        {headers &&
          headers?.map((ele, i) =>
            ele.name === " "
              ? parentEle?.items?.length > 0 &&
                index === 0 && (
                  <>
                    <StyledTableCell
                      rowSpan={spanRw}
                      align="center"
                      style={{
                        minWidth: +ele?.width,
                        width: +ele?.width,
                      }}
                    >
                      {filterStateList?.length > 0 &&
                      selectedStatus !== null ? (
                        <Checkbox
                          checked={parentEle.items.some((item) =>
                            SelectedTransactionIds.includes(item.id)
                          )}
                          onChange={(e) => {
                            handleCheckboxChange(e.target.checked, parentEle);
                          }}
                          color="primary"
                        />
                      ) : (
                        // ""
                        <IconButton
                          style={{
                            display: checkModuleExist(endPoints, "Copy Transaction")
                              ? "inline-flex"
                              : "none",
                          }}
                          onClick={() =>
                            navigate("/addTransaction", {
                              state: {
                                data: row,
                                parentEle: parentEle,
                                site: site,
                                collapse: showAllTransaction,
                                type: "copy",
                              },
                            })
                          }
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )}
                    </StyledTableCell>
                  </>
                )
              : ele.name === "S.NO"
              ? parentEle?.items?.length > 0 &&
                index === 0 && (
                  <StyledTableCell
                    rowSpan={spanRw}
                    style={{
                      minWidth: +ele?.width,
                      width: +ele?.width,
                      // textAlign: "center"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        variant="body2"
                        // style={{
                        //   textAlign: row?.childList?.length > 0 ? "justify" : null
                        // }}
                      >
                        {count}
                        {row?.childList?.length > 0 ? (
                          <IconButton
                            aria-label="expand rowData"
                            size="small"
                            onClick={() => handleClickIcon(row)}
                          >
                            {open ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        ) : null}
                      </Typography>
                    </div>
                  </StyledTableCell>
                )
              : ""
          )}

        {headers &&
          headers?.map((ele, i) =>
            ele.name === "Transaction Date" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: +ele?.width,
                  width: +ele?.width,
                }}
              >
                {moment(row?.date).format("DD-MM-YYYY, hh:mm A")}
              </StyledTableCell>
            ) : ele.name === "Txn Id" && ele.isShow ? (
              <StyledTableCell
                style={{
                  cursor: "pointer",
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                }}
                onClick={() =>
                  navigate("/addTransaction", {
                    state: {
                      id: row?.txnId,
                      site: site,
                      path: "daybook",
                    },
                  })
                }
              >
                <Editedtext
                  text={row?.txnId}
                  num={24}
                  textColor="blue"
                  fWaight={700}
                />
              </StyledTableCell>
            ) : ele.name === "Debit Account" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    checkPermissionExist(endPoints, singleTransactionGetUrl) &&
                      navigate("/generalLedger", {
                        state: {
                          id: row?.sourceAccountId,
                          name: row?.sourceAccountName,
                          code: row?.sourceAccountCode,
                          backPath: "daybook",
                          balance: row?.sourceAccountClosingBalance,
                          balanceType: row?.sourceAccountClosingBalanceType,
                        },
                      });
                  }}
                >
                  {row?.sourceAccountName != "temp" &&
                  row?.sourceAccountName != "Temp" ? (
                    <div>
                      <p>{row?.sourceAccountName}</p>
                      <p style={{ color: "blue" }}>
                        {"("}
                        {row?.sourceAccountCode}
                        {")"}
                      </p>
                    </div>
                  ) : (
                    "-"
                  )}
                </div>
              </StyledTableCell>
            ) : ele.name === "Credit Account" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: +ele?.width,
                  width: +ele?.width,
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    checkPermissionExist(endPoints, singleTransactionGetUrl) &&
                      navigate("/generalLedger", {
                        state: {
                          id: row?.targetAccountId,
                          name: row?.targetAccountName,
                          code: row?.targetAccountCode,
                          backPath: "daybook",
                          balance: row?.targetAccountClosingBalance,
                          balanceType: row?.targetAccountClosingBalanceType,
                        },
                      });
                  }}
                >
                  {row?.targetAccountName != "temp" &&
                  row?.targetAccountName != "Temp" ? (
                    <div>
                      {" "}
                      <p>{row?.targetAccountName}</p>
                      <p style={{ color: "blue" }}>
                        {"("}
                        {row?.targetAccountCode}
                        {")"}
                      </p>
                    </div>
                  ) : (
                    "-"
                  )}
                </div>
              </StyledTableCell>
            ) : ele.name === "Amount" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  textAlign: "end",
                  width: +ele?.width,
                }}
              >
                {<AmountInfo amount={row?.amount} color="#000000de" />}
              </StyledTableCell>
            ) : ele.name === "Voucher" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                }}
              >
                <Typography
                  sx={{ maxWidth: "200px", overflowWrap: "break-word" }}
                >
                  {row?.voucherTemplateId &&
                  voucherList &&
                  voucherList.length > 0
                    ? voucherList.find(
                        (val) => val.id === row.voucherTemplateId
                      )?.customFormType
                    : "-"}
                </Typography>
              </StyledTableCell>
            ) : ele.name === "Created On" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                }}
              >
                {moment(row?.createdOn).format("DD-MM-YYYY, hh:mm A")}
              </StyledTableCell>
            ) : ele.name === "Created By" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                }}
              >
                {row?.createdByName ? (
                  <Editedtext text={row?.createdByName} num="25" />
                ) : (
                  "-"
                )}
              </StyledTableCell>
            ) : ele.name === "Status" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                }}
              >
                {statusName?.[0]?.name}
              </StyledTableCell>
            ) : ele.name === "Account Code" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                  textAlign: "center",
                }}
              >
                {row?.accountCodeName || "-"}
              </StyledTableCell>
            ) : ele.name === "Transaction Type" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                  textAlign: "center",
                }}
              >
                {row?.accountTransactionTypeName || "-"}
              </StyledTableCell>
            ) : ele.name === "Invoice No" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                  textAlign: "center",
                }}
              >
                {row?.invoiceNo || "-"}
              </StyledTableCell>
            ) : ele.name === "Due Amount" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                  textAlign: "right",
                }}
              >
                <AmountInfo amount={row?.dueAmount} color="#000000de" />
              </StyledTableCell>
            ) : ele.name === "Remaining Settled Amount" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                  textAlign: "right",
                }}
              >
                <AmountInfo
                  amount={row?.remainingSettledAmount}
                  color="#000000de"
                />
              </StyledTableCell>
            ) : ele.name === "Over Due Days" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                  textAlign: "center",
                }}
              >
                {row?.overDueDays || 0}
              </StyledTableCell>
            ) : ele.name === "Over Due Count" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                  textAlign: "center",
                }}
              >
                {row?.overDueDaysCount || 0}
              </StyledTableCell>
            ) : ele.name === "Payment Status" && ele.isShow ? (
              <StyledTableCell
                style={{
                  minWidth: `${ele?.width}px`,
                  width: +ele?.width,
                  textAlign: "center",
                  fontWeight: "bold",
                  color:
                    row?.paymentStatus === "PENDING"
                      ? "red"
                      : row?.paymentStatus === "PARTIAL"
                      ? "#ff7905"
                      : "green",
                }}
              >
                {row?.paymentStatus || "-"}
              </StyledTableCell>
            ) : ele.name === "CGST" && ele.isShow ? (
              parentEle.items.length > 0 && (
                <StyledTableCell
                  // rowSpan={spanRw}
                  align="center"
                  style={{
                    minWidth: `${ele?.width}px`,
                    textAlign: "right",
                    width: +ele?.width,
                  }}
                >
                  {row?.isCgstTransaction ? (
                    <AmountInfo amount={row?.amount} color="#000000de" />
                  ) : (
                    "-"
                  )}
                </StyledTableCell>
              )
            ) : ele.name === "IGST" && ele.isShow ? (
              parentEle.items.length > 0 && (
                <StyledTableCell
                  // rowSpan={spanRw}
                  align="center"
                  style={{
                    minWidth: `${ele?.width}px`,
                    textAlign: "right",
                    width: +ele?.width,
                  }}
                >
                  {row?.isIgstTransaction ? (
                    <AmountInfo amount={row?.amount} color="#000000de" />
                  ) : (
                    "-"
                  )}
                </StyledTableCell>
              )
            ) : ele.name === "SGST" && ele.isShow ? (
              parentEle.items.length > 0 && (
                <StyledTableCell
                  // rowSpan={spanRw}
                  align="center"
                  style={{
                    minWidth: `${ele?.width}px`,
                    textAlign: "right",
                    width: +ele?.width,
                  }}
                >
                  {row?.isSgstTransaction ? (
                    <AmountInfo amount={row?.amount} color="#000000de" />
                  ) : (
                    "-"
                  )}
                </StyledTableCell>
              )
            ) : ele.name === "Total Amount" && ele.isShow ? (
              parentEle?.items?.length > 0 &&
              index === 0 && (
                <StyledTableCell
                  rowSpan={spanRw}
                  align="right"
                  style={{
                    minWidth: `${ele?.width}px`,
                    textAlign: "right",
                    width: +ele?.width,
                  }}
                >
                  {+parseInt(Math.abs(parentEle?.totalAmount)) && (
                    <AmountInfo
                      amount={parentEle?.totalAmount}
                      color="#000000de"
                      fontWeight="700"
                    />
                  )}
                </StyledTableCell>
              )
            ) : row.hasOwnProperty(ele?.alias) && ele.isShow ? (
              <StyledTableCell
                key={ele.alias}
                style={{
                  minWidth: 250,
                  textAlign: "right",
                }}
              >
                <span>
                  <AmountInfo amount={row[ele?.alias]} />
                </span>
              </StyledTableCell>
            ) : (
              ""
            )
          )}
      </StyledTableRow>
      {/* Collapsible table to render the childList --->>> ChildList contains the data.items info without first indexing items info, and the first index is the parent of the childList */}
      {!showAllTransaction ? (
        <StyledTableRow
          style={{
            pointerEvents:
              row?.balanceProcessStatus === "Processed" ? "all" : "none",
          }}
        >
          <StyledTableCell
            colSpan={headers.length > 0 ? headers.length : 15}
            sx={{
              padding: "0px !important",
            }}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table aria-label="purchases table">
                <TableBody>
                  {row &&
                    row?.childList &&
                    row?.childList.length > 0 &&
                    row?.childList.map((row, index) => (
                      <>
                        <StyledTableRow
                          style={{ backgroundColor: getSameValue.color }}
                        >
                          {headers &&
                            headers?.map((ele, i) =>
                              ele.name === " " ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: +ele?.width,
                                    width: +ele?.width,
                                  }}
                                ></StyledTableCell>
                              ) : ele.name === "S.NO" ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: +ele?.width,
                                    width: +ele?.width,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  ></div>
                                </StyledTableCell>
                              ) : (
                                ""
                              )
                            )}

                          {headers &&
                            headers?.map((ele, i) =>
                              ele.name === "Transaction Date" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: +ele?.width,
                                    width: +ele?.width,
                                  }}
                                >
                                  {moment(row?.date).format(
                                    "DD-MM-YYYY, hh:mm A"
                                  )}
                                </StyledTableCell>
                              ) : ele.name === "Txn Id" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    cursor: "pointer",
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                  onClick={() =>
                                    navigate("/addTransaction", {
                                      state: {
                                        id: row?.txnId,
                                        site: site,
                                        path: "daybook",
                                      },
                                    })
                                  }
                                >
                                  <Editedtext
                                    text={row?.txnId}
                                    num={24}
                                    textColor="blue"
                                    fWaight={700}
                                  />
                                </StyledTableCell>
                              ) : ele.name === "Debit Account" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                >
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      checkPermissionExist(
                                        endPoints,
                                        singleTransactionGetUrl
                                      ) &&
                                        navigate("/generalLedger", {
                                          state: {
                                            id: row?.sourceAccountId,
                                            name: row?.sourceAccountName,
                                            code: row?.sourceAccountCode,
                                            backPath: "daybook",
                                            balance:
                                              row?.sourceAccountClosingBalance,
                                            balanceType:
                                              row?.sourceAccountClosingBalanceType,
                                          },
                                        });
                                    }}
                                  >
                                    {row?.sourceAccountName != "temp" &&
                                    row?.sourceAccountName != "Temp" ? (
                                      <div>
                                        <p>{row?.sourceAccountName}</p>
                                        <p style={{ color: "blue" }}>
                                          {"("}
                                          {row?.sourceAccountCode}
                                          {")"}
                                        </p>
                                      </div>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                </StyledTableCell>
                              ) : ele.name === "Credit Account" &&
                                ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                >
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      checkPermissionExist(
                                        endPoints,
                                        singleTransactionGetUrl
                                      ) &&
                                        navigate("/generalLedger", {
                                          state: {
                                            id: row?.targetAccountId,
                                            name: row?.targetAccountName,
                                            code: row?.targetAccountCode,
                                            backPath: "daybook",
                                            balance:
                                              row?.targetAccountClosingBalance,
                                            balanceType:
                                              row?.targetAccountClosingBalanceType,
                                          },
                                        });
                                    }}
                                  >
                                    {row?.targetAccountName != "temp" &&
                                    row?.targetAccountName != "Temp" ? (
                                      <div>
                                        {" "}
                                        <p>{row?.targetAccountName}</p>
                                        <p style={{ color: "blue" }}>
                                          {"("}
                                          {row?.targetAccountCode}
                                          {")"}
                                        </p>
                                      </div>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                </StyledTableCell>
                              ) : ele.name === "Amount" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    textAlign: "end",
                                    width: +ele?.width,
                                  }}
                                >
                                  {
                                    <AmountInfo
                                      amount={row?.amount}
                                      color="#000000de"
                                    />
                                  }
                                </StyledTableCell>
                              ) : ele.name === "Voucher" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: "290px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      maxWidth: "200px",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {row?.voucherTemplateId &&
                                    voucherList &&
                                    voucherList.length > 0
                                      ? voucherList.find(
                                          (val) =>
                                            val.id === row.voucherTemplateId
                                        )?.customFormType
                                      : "-"}
                                  </Typography>
                                </StyledTableCell>
                              ) : ele.name === "Created On" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                >
                                  {moment(row?.createdOn).format(
                                    "DD-MM-YYYY, hh:mm A"
                                  )}
                                </StyledTableCell>
                              ) : ele.name === "Created By" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                >
                                  {row?.createdByName ? (
                                    <Editedtext
                                      text={row?.createdByName}
                                      num="25"
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </StyledTableCell>
                              ) : ele.name === "Status" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                >
                                  {statusName?.[0]?.name}
                                </StyledTableCell>
                              ) : ele.name === "Account Code" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                    textAlign: "center",
                                  }}
                                >
                                  {row?.accountCodeName || "-"}
                                </StyledTableCell>
                              ) : ele.name === "Transaction Type" &&
                                ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                    textAlign: "center",
                                  }}
                                >
                                  {row?.accountTransactionTypeName || "-"}
                                </StyledTableCell>
                              ) : ele.name === "Invoice No" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                    textAlign: "center",
                                  }}
                                >
                                  {row?.invoiceNo || "-"}
                                </StyledTableCell>
                              ) : ele.name === "Due Amount" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                    textAlign: "right",
                                  }}
                                >
                                  <AmountInfo
                                    amount={row?.dueAmount}
                                    color="#000000de"
                                  />
                                </StyledTableCell>
                              ) : ele.name === "Remaining Settled Amount" &&
                                ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                    textAlign: "right",
                                  }}
                                >
                                  <AmountInfo
                                    amount={row?.remainingSettledAmount}
                                    color="#000000de"
                                  />
                                </StyledTableCell>
                              ) : ele.name === "Over Due Days" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                    textAlign: "center",
                                  }}
                                >
                                  {row?.overDueDays || 0}
                                </StyledTableCell>
                              ) : ele.name === "Over Due Count" &&
                                ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                    textAlign: "center",
                                  }}
                                >
                                  {row?.overDueDaysCount || 0}
                                </StyledTableCell>
                              ) : ele.name === "Payment Status" &&
                                ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    color:
                                      row?.paymentStatus === "PENDING"
                                        ? "red"
                                        : row?.paymentStatus === "PARTIAL"
                                        ? "#ff7905"
                                        : "green",
                                  }}
                                >
                                  {row?.paymentStatus || "-"}
                                </StyledTableCell>
                              ) : ele.name === "CGST" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                  align="right"
                                >
                                  {row?.isCgstTransaction ? (
                                    <AmountInfo
                                      amount={row?.amount}
                                      color="#000000de"
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </StyledTableCell>
                              ) : ele.name === "IGST" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                  align="right"
                                >
                                  {row?.isIgstTransaction ? (
                                    <AmountInfo
                                      amount={row?.amount}
                                      color="#000000de"
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </StyledTableCell>
                              ) : ele.name === "SGST" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                  align="right"
                                >
                                  {row?.isSgstTransaction ? (
                                    <AmountInfo
                                      amount={row?.amount}
                                      color="#000000de"
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </StyledTableCell>
                              ) : ele.name === "Total Amount" && ele.isShow ? (
                                <StyledTableCell
                                  style={{
                                    minWidth: `${ele?.width}px`,
                                    width: +ele?.width,
                                  }}
                                  colSpan={1}
                                ></StyledTableCell>
                              ) : row.hasOwnProperty(ele?.alias) &&
                                ele.isShow ? (
                                <StyledTableCell
                                  key={ele.alias}
                                  style={{
                                    minWidth: 250,
                                    textAlign: "right",
                                  }}
                                >
                                  <span>
                                    <AmountInfo amount={row[ele?.alias]} />
                                  </span>
                                </StyledTableCell>
                              ) : (
                                ""
                              )
                            )}
                        </StyledTableRow>

                        {/* <StyledTableRow
                          style={{ backgroundColor: getSameValue.color }}
                        >
                          {filteredColumns &&
                            filteredColumns.length > 0 &&
                            filteredColumns.map((column) => (
                              <StyledTableCell
                                width={"250px"}
                                style={{ textAlign: "right" }}
                              >
                                <span>
                                  <AmountInfo amount={row[column?.alias]} />
                                </span>
                              </StyledTableCell>
                            ))}
                        </StyledTableRow> */}
                      </>
                    ))}
                </TableBody>
              </Table>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      ) : null}
    </React.Fragment>
  );
}

export default function TransactionTable({
  data,
  pageNo,
  setPageNo,
  totalPages,
  setPageSize,
  pageSize,
  handleRevert,
  site,
  mainTransactionData,
  voucherList,
  nextActionList,
  selectedStatus,
  filterStateList,
  handleCheckboxChange,
  path,
  SelectedTransactionIds,
  showAllTransaction,
  selectedColumns,
  headerConfig,
}) {
  const onPageChange = (event, value) => setPageNo(value);
  const [dataIndexRendering, setDataIndexRendering] = React.useState([]);

  const filteredColumns =
    selectedColumns &&
    selectedColumns.length > 0 &&
    selectedColumns.filter((val) => val.alias != "all");

  let currentVal;
  let currentCondition = true;
  let rowSpanCount = 0;
  const getSameValue = (val) => {
    if (currentVal) {
      if (currentVal !== val?.txnId) {
        currentVal = val?.txnId;
        currentCondition = !currentCondition;
        rowSpanCount = 0;
      }
    } else {
      currentVal = val?.txnId;
    }
    // if (currentCondition) {
    //   rowSpanCount += 1;
    // } else {
    //   rowSpanCount += 1;
    // }
    const styleData = {
      color: currentCondition ? "#dddddd" : "#fff",
      rowSpan: rowSpanCount,
    };
    // return currentCondition ? "#7aaed3" : "#959595";
    return styleData;
  };

  let counts = 0;

  React.useEffect(() => {
    const mainTransaction = mainTransactionData.map((ele, index) => {
      const childList = [];
      for (let i = 1; i < ele.items.length; i++) {
        childList.push(ele.items[i]);
      }
      return {
        ...ele,
        items: [
          {
            ...ele.items[0],
            childList: childList,
          },
        ],
      };
    });
    setDataIndexRendering(mainTransaction);
  }, [mainTransactionData]);

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ marginTop: 20, height: data && data.length > 0 && "calc(100vh - 263px)" }}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <StyledTableRow>
              {/* {headers &&
                Array.isArray(headers) &&
                headers.length > 0 &&
                headers.map(
                  (val, index) =>
                    index < 11 && (
                      <StyledTableCell
                        key={val.id}
                        align={val?.textAlign}
                        style={{
                          minWidth: +val?.width
                        }}
                      >
                        {val.name}
                      </StyledTableCell>
                    )
                )} */}

              {headerConfig &&
                headerConfig.length > 0 &&
                headerConfig.map((column, columnIndex) =>
                  column.name === "S.NO" ? (
                    <React.Fragment>
                      <StyledTableCell
                        style={{
                          minWidth: +column?.width || 250,
                          textAlign: column?.textAlign || "left",
                        }}
                      >
                        {column.name}
                      </StyledTableCell>
                    </React.Fragment>
                  ) : (
                    column.name === " " && (
                      <React.Fragment>
                        <StyledTableCell
                          style={{
                            minWidth: +column?.width || 250,
                            textAlign: column?.textAlign || "left",
                          }}
                        ></StyledTableCell>
                      </React.Fragment>
                    )
                  )
                )}
              {headerConfig &&
                headerConfig.length > 0 &&
                headerConfig.map(
                  (column, columnIndex) =>
                    column.name !== "S.NO" &&
                    column.name !== " " &&
                    column.isShow && (
                      <React.Fragment>
                        <StyledTableCell
                          style={{
                            minWidth: +column?.width || 250,
                            textAlign: column?.textAlign || "right",
                          }}
                        >
                          {column.name}
                        </StyledTableCell>
                      </React.Fragment>
                    )
                )}
              {/* {headers &&
                Array.isArray(headers) &&
                headers.length > 0 &&
                headers.map(
                  (val, index) =>
                    index > 10 && (
                      <StyledTableCell
                        key={val.id}
                        align={val?.textAlign}
                        style={{
                          minWidth: +val?.width,
                        }}
                      >
                        {val.name}
                      </StyledTableCell>
                    )
                )} */}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!showAllTransaction
              ? dataIndexRendering &&
                dataIndexRendering.map((ele) => {
                  return ele.items.map((item, index) => {
                    const bgColor = getSameValue(item);
                    counts += 1;
                    return (
                      <Row
                        key={item.id}
                        row={item}
                        index={index}
                        parentInd={index}
                        count={counts}
                        // index={(pageNo - 1) * pageSize + index + 1}
                        handleRevert={handleRevert}
                        site={site}
                        getSameValue={bgColor}
                        parentEle={ele}
                        spanRw={ele.items.length}
                        voucherList={voucherList}
                        nextActionList={nextActionList}
                        selectedStatus={selectedStatus}
                        filterStateList={filterStateList}
                        handleCheckboxChange={handleCheckboxChange}
                        path={path}
                        isSelected={item?.isSelected}
                        SelectedTransactionIds={SelectedTransactionIds}
                        headers={headerConfig}
                        showAllTransaction={showAllTransaction}
                        filteredColumns={filteredColumns}
                      />
                    );
                  });
                })
              : mainTransactionData &&
                mainTransactionData.map((ele) => {
                  counts += 1;
                  return ele.items.map((item, index) => {
                    const bgColor = getSameValue(item);
                    return (
                      <Row
                        key={item.id}
                        row={item}
                        index={index}
                        parentInd={index}
                        count={counts}
                        // index={(pageNo - 1) * pageSize + index + 1}
                        handleRevert={handleRevert}
                        site={site}
                        getSameValue={bgColor}
                        parentEle={ele}
                        spanRw={ele.items.length}
                        voucherList={voucherList}
                        nextActionList={nextActionList}
                        selectedStatus={selectedStatus}
                        filterStateList={filterStateList}
                        handleCheckboxChange={handleCheckboxChange}
                        path={path}
                        isSelected={item?.isSelected}
                        SelectedTransactionIds={SelectedTransactionIds}
                        headers={headerConfig}
                        showAllTransaction={showAllTransaction}
                        filteredColumns={filteredColumns}
                      />
                    );
                  });
                })}
          </TableBody>
        </Table>
      </TableContainer>
      {data && data.length < 1 && <DataStatus />}
      {data && Array.isArray(data) && data.length > 0 && (
        <CommonPagination
          pageSize={pageSize}
          pageSizes={pageSizes}
          totalPages={totalPages}
          pageNo={pageNo}
          setPageNo={setPageNo}
          setPageSize={setPageSize}
          onPageChange={onPageChange}
          style={{ minWidth: 180, margin: "0  20px" }}
          label="Transaction Per Page"
        />
      )}
    </>
  );
}
