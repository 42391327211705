import React from "react";
import { IconButton, Button, Typography, Divider, Dialog } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

const GroupModal = ({ open, closeDialogBox, groups }) => {
  
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            style={{ width: "600px", margin: "auto" }}
        >
            <div id="alert-dialog-title" style={{ display: "flex", justifyContent: "space-between", padding: "5px" }}>
                <Typography variant="h6">Groups</Typography>
                <IconButton onClick={() => closeDialogBox()}>
                    <ClearIcon />
                </IconButton>
            </div>
            <Divider />

            <div>
                {
                    groups && groups[0].list && Array.isArray(groups[0].list) && groups[0].list.length > 0 && groups[0].list.map(val =>

                        <Button
                            // key={val?.id}
                            disabled
                            className="account_group_btn"
                            sx={{ margin: "10px 5px", background: groups[0]?.type === "Debit" ? "green" : groups[0]?.type === "Credit" ? "#a11919" : "#61a3e8", color: "#fff !important" }}
                            size="small"
                        
                        // variant="contained"
                        >
                            {val?.name}
                        </Button>
                    )
                }
            </div>


        </Dialog>
    );
};

export default GroupModal;
