import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { sitesReducer } from "./sitesReducer";
import { siteReducer, siteDisableReducer } from "./siteReducer";
import accountReducer from "./accountReducer";
import accountTypeReducer from "./accountTypeReducer";
import { permissionReducer } from "./PermissionReducer";
import { fyReducer, fymReducer } from "./FYReducer";
import { companyReducer } from "./companyReducer";
import { searchReducer } from "./SearchReducer";
import paymentRequestReducer from "./paymentRequestReducer";
import { permissionStateReducer } from "./PermissionStateReducer";

const rootReducer = combineReducers({
  stateReducer: permissionStateReducer,
  loginReducer: loginReducer,
  sitesReducer: sitesReducer,
  siteReducer: siteReducer,
  siteDisableReducer: siteDisableReducer,
  accountReducer,
  accountTypeReducer,
  fyReducer,
  permissionReducer,
  companyReducer,
  paymentListReducer: paymentRequestReducer,
  searchReducer,
  fymReducer,
});
export default rootReducer;
