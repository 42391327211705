import { legacy_createStore, combineReducers, compose, applyMiddleware } from "redux";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import thunk from "redux-thunk";
import rootReducer from "../reducers/index";

const persistConfig = {
  key: 'root',
  storage,
};

const combineReducer = combineReducers({
  rootReducer
});

const persistedReducer = persistReducer(persistConfig, combineReducer);

const configureStore = () => {
  return legacy_createStore(persistedReducer, compose(applyMiddleware(thunk)));
};

export default configureStore;
