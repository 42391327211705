import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Pagination,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Paper,
  IconButton,
  Autocomplete,
  TextField,
  Collapse,
  Divider,
  Button,
  TableRow,
  TableCell,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FcInfo } from "react-icons/fc";
import { headers } from "./util";
import moment from "moment";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import InfoIcon from "@mui/icons-material/Info";
import { DataStatus, updateNestedItem } from "../utils/index";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { pageSizes } from "../Account/util";
import { StyledTableCell, StyledTableRow } from "../utils/index";
// import Color from "color";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { FaDollarSign, FaFontAwesome, FaRupeeSign } from "react-icons/fa";
import { NumberFormatter } from "../CommonComps/NumberFormatter";
import AmountInfo from "../CommonComps/AmountInfo";
import { getEncryptPayload } from "../Network_Utility";
import { reportChildListUrl, trialBalanceUrl } from "../config";
import { userService } from "../userService";
import { AutoSizer, List } from "react-virtualized";
import ColorInfo from "../CommonComps/ColorInfo";

// function getColorForLevel(level) {
//   // Define a color scheme based on levels
//   const colorSchemes = [
//     "#DDEEFF", // Dark Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//     "#F5FAFF", // Medium Grey
//   ];

//   // Use modulo to cycle through colors
//   return colorSchemes[level % colorSchemes.length];
// }

function Row({
  childRow,
  index,
  handleRevert,
  site,
  level,
  item,
  color,
  setLoading,
  successAlert,
  errorAlert,
  getApprovedBal,
  tableData,
  setData,
  totalProfit,
  navigateToLedger,

  fromDate,
  toDate,
  monthId,
  showOpeningBalance,
}) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { childList } = childRow;

  const [rowData, setRowData] = useState(childRow || {});
  const [isAccount, setIsAccount] = useState(false);

  const isLeafNode = !childList || childList.length == 0;

  const handleClickIcon = (row) => {
    if (!isCollapsed) {
      if (!row.childList || (row.childList && row.childList.length == 0)) {
        makeAPICallOnLeafNode(row);
        // const permissionExist = checkPermissionExist(endPoints, trialBalanceUrl)
        // if (permissionExist)

        // else errorAlert(`Unautharized to access accounts trial balance of ${row.name} group.`)
      } else setIsCollapsed(!isCollapsed);
      // Set isCollapsed to true before making the API call
    } else setIsCollapsed(!isCollapsed); // Toggle isCollapsed state
  };

  const makeAPICallOnLeafNode = async (row) => {
    const payload = {
      reportId: 5,
      groupId: row?.id,
      siteId: localStorage.getItem("siteId"),
      monthId: monthId,
      fromDate: fromDate,
      toDate: toDate,
    };

    const encryptedPayload = getEncryptPayload(payload, true);

    const apiUrl = reportChildListUrl;

    try {
      setLoading(true);
      const response = await userService.postData(apiUrl, encryptedPayload);

      if (response && response.data && response.data.status == 200) {
        if (
          response?.data?.data &&
          response?.data?.data.length > 0 &&
          response?.data?.data[0]?.childList?.length > 0
        ) {
          const updatedRow = {
            ...row,
            childList: response?.data?.data[0]?.childList,
          };
          setRowData(updatedRow);

          // const updatedData = updateNestedItem(tableData, updatedRow)
          // setData(updatedData);

          setIsCollapsed(true);
        } else getAccountsList(row);
      } else {
        // Handle API error
        errorAlert(response?.data?.message);
        console.error("API Error:", response.data.message);
      }
    } catch (error) {
      // Handle API call error
      console.error("API Call Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const getAccountsList = async (row) => {
    const payload = {
      monthId: monthId,
      fromDate: fromDate,
      toDate: toDate,
      siteId: parseInt(localStorage.getItem("siteId")),
      accountGroupIds: [row?.id],
      tokenData: {
        companyId: "11",
        moduleId: null,
        organizationId: null,
        userCode: null,
        userId: "14000",
        userName: null,
        userRoleId: null,
      },
      financialYearId: 5,
    };

    const apiUrl = trialBalanceUrl;

    try {
      setLoading(true);
      const response = await userService.postData(apiUrl, payload);

      if (response && response.data && response.data.status === 200) {
        const { accountInfoList } = response.data.data;

        if (accountInfoList && accountInfoList.length > 0) {
          setIsCollapsed(true);
          setIsAccount(true);
          const newList = accountInfoList.map((item) => ({
            ...item,
            accountId: item?.accountId,
            name: item.accountName,

            isAccount: true,
            childList: [], // Make sure to set childList as an empty array
          }));

          const updatedRow = { ...row, childList: newList };
          setRowData(updatedRow);

          // Update the main data array with the updated row
          // const updatedData = updateNestedItem(tableData, updatedRow)
          // setData(updatedData);

          // Toggle isCollapsed state
        } else {
          // If there are no child items, just toggle isCollapsed state
          // setIsCollapsed(!isCollapsed);
          successAlert(`No Account available for ${row.name}`);
        }
      } else {
        // Handle API error
        errorAlert(response?.data?.message);
        console.error("API Error:", response.data.message);
      }
    } catch (error) {
      // Handle API call error
      console.error("API Call Error:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // const color = getColorForLevel(level);
  const paddingLeft = `10px!important`;

  useEffect(() => {
    childRow && setRowData(childRow);
  }, [childRow]);

  return (
    <React.Fragment>
      <StyledTableRow
        className="plRow"
        sx={{
          cursor: "pointer",
          backgroundColor: `${color}!important`,
          marginTop: "1rem!important",
        }}
        // onClick={() =>
        //   row?.childList?.length == 0

        //     ? toggleAccountTransactions(row)
        //     : toggleCollapse()
        // }
      >
        {/* <StyledTableCell
          sx={{ padding: "5px 0px!important", paddingLeft: paddingLeft }}
        >
          {showExpandIcon ? (
            <IconButton
              onClick={toggleCollapse}
              sx={{ padding: "0px!important" }}
            >
              {isCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          ) : (
            <IconButton
              onClick={toggleCollapse}
              sx={{ padding: "0px!important" }}
            >
              <ExpandMoreIcon sx={{ visibility: "hidden" }} />
            </IconButton>
          )}
          {row?.childList?.length == 0 && !row?.isAccount && (
            <IconButton
              onClick={() => toggleAccountTransactions(row)}
              sx={{ padding: "0px!important" }}
            >
              {isAccountTransactionsOpen ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
          )}
          <span style={{ color: row?.isAccount && "blue" }} >{row?.name}</span>
        </StyledTableCell> */}

        {rowData && !rowData?.isAccount ? (
          <StyledTableCell
            style={{
              padding: "5px 0px!important",
              paddingLeft: paddingLeft,
            }}
          >
            <Typography variant="body2" className="table_value">
              <IconButton
                aria-label="expand rowData"
                size="small"
                onClick={() => handleClickIcon(rowData)}
              >
                {isCollapsed ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
              <span style={{ color: rowData?.isAccount && "blue" }}>
                {rowData?.name}
              </span>
            </Typography>
          </StyledTableCell>
        ) : (
          <StyledTableCell
            style={{
              padding: "5px 0px!important",
              paddingLeft: paddingLeft,
            }}
          >
            <Typography variant="body2" className="table_value">
              <IconButton disabled>
                <KeyboardArrowUpIcon style={{ visibility: "hidden" }} />
              </IconButton>
              <span
                style={{ color: rowData?.isAccount && "blue" }}
                onClick={() => navigateToLedger(rowData)}
              >
                {rowData?.name}
              </span>
            </Typography>
          </StyledTableCell>
        )}

        <StyledTableCell sx={{ padding: "5px 0px!important" }}>
          {!getApprovedBal ? (
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginRight: "10px",
                // paddingRight:
                //   row?.amount == undefined || row?.amount == 0 ? "22px" : "0px",
              }}
            >
              <span
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color:
                    rowData?.accountGroupCurrentBalance -
                      rowData?.openingBalance ==
                    0
                      ? "black"
                      : rowData?.accountGroupCurrentBalance -
                          rowData?.openingBalance >
                        0
                      ? "red"
                      : "green",
                }}
              >
                {rowData?.openingBalance ||
                rowData?.accountGroupCurrentBalance ? (
                  <>
                    {showOpeningBalance && (
                      <span style={{ color: "#2d269f", padding: "0px 2px" }}>
                        <AmountInfo amount={rowData?.openingBalance} />
                        {" | "}{" "}
                      </span>
                    )}
                    <AmountInfo
                      amount={
                        rowData?.accountGroupCurrentBalance -
                        rowData?.openingBalance
                      }
                    />
                  </>
                ) : (
                  "-"
                )}
              </span>
            </span>
          ) : (
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",

                // paddingRight:
                //   rowData?.amount == undefined || rowData?.amount == 0 ? "22px" : "0px",
              }}
            >
              {" "}
              <span
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginRight: "10px",
                  color: rowData?.approvedAccountGroupCurrentBalance
                    ? rowData?.approvedAccountGroupCurrentBalance -
                        rowData?.approvedOpeningBalance ==
                      0
                      ? "black"
                      : rowData?.approvedAccountGroupCurrentBalance -
                          rowData?.approvedOpeningBalance >
                        0
                      ? "red"
                      : "green"
                    : rowData?.approvedClosingBalance
                    ? rowData?.approvedClosingBalance -
                        rowData?.approvedOpeningBalance ==
                      0
                      ? "black"
                      : rowData?.approvedClosingBalance -
                          rowData?.approvedOpeningBalance >
                        0
                      ? "red"
                      : "green"
                    : "green",
                  // color:
                  //   rowData?.approvedAccountGroupCurrentBalance -
                  //     rowData?.approvedOpeningBalance ==
                  //   0
                  //     ? "black"
                  //     : rowData?.approvedAccountGroupCurrentBalance -
                  //         rowData?.approvedOpeningBalance >
                  //       0
                  //     ? "red"
                  //     : "green",
                }}
              >
                {/* {rowData?.approvedOpeningBalance ||
                rowData?.approvedAccountGroupCurrentBalance ? (
                  <AmountInfo
                    amount={
                      rowData?.approvedAccountGroupCurrentBalance -
                      rowData?.approvedOpeningBalance
                    }
                  />
                ) : (
                  "-"
                )} */}

                {rowData?.approvedAccountGroupCurrentBalance ? (
                  rowData?.approvedOpeningBalance ||
                  rowData?.approvedAccountGroupCurrentBalance ? (
                    <>
                      {showOpeningBalance && (
                        <span style={{ color: "#2d269f", padding: "0px 2px" }}>
                          <AmountInfo amount={rowData?.openingBalance || 0} />
                          {" | "}{" "}
                        </span>
                      )}
                      <AmountInfo
                        amount={
                          rowData?.approvedAccountGroupCurrentBalance -
                          rowData?.approvedOpeningBalance
                        }
                      />
                    </>
                  ) : (
                    "-"
                  )
                ) : rowData?.approvedClosingBalance ? (
                  rowData?.approvedOpeningBalance ||
                  rowData?.approvedClosingBalance ? (
                    <>
                      {showOpeningBalance && (
                        <span style={{ color: "#2d269f", padding: "0px 2px" }}>
                          <AmountInfo amount={rowData?.openingBalance || 0} />
                          {" | "}{" "}
                        </span>
                      )}
                      <AmountInfo
                        amount={
                          rowData?.approvedClosingBalance -
                          rowData?.approvedOpeningBalance
                        }
                      />
                    </>
                  ) : (
                    "-"
                  )
                ) : (
                  "-"
                )}
              </span>
            </span>
          )}
        </StyledTableCell>
      </StyledTableRow>

      <StyledTableRow>
        <StyledTableCell
          colSpan={3}
          sx={{ padding: "5px 0px!important", borderBottom: "none" }}
        >
          <Collapse in={isCollapsed}>
            <div>
              <TableContainer>
                <Table>
                  <TableBody>
                    {isAccount && rowData ? (
                      <AutoSizer style={{ height: "300px" }}>
                        {({ height, width }) => {
                          const itemsPerRow = 1;

                          return (
                            rowData.childList &&
                            rowData.childList.length > 0 && (
                              <List
                                width={width}
                                height={300}
                                rowHeight={50}
                                rowCount={Math.ceil(
                                  rowData.childList?.length / itemsPerRow
                                )}
                                overscanRowCount={3}
                                rowRenderer={({ index, key, style }) => {
                                  const startIndex = index * itemsPerRow;
                                  const endIndex = Math.min(
                                    startIndex + itemsPerRow,
                                    rowData.childList?.length
                                  );
                                  const items = rowData.childList
                                    ?.slice(startIndex, endIndex)
                                    .map((val, ind) => (
                                     <>
                                     <StyledTableCell
                                      style={{
                                        paddingLeft: paddingLeft,
                                      }}
                                    >
                                      <Typography variant="body2" className="table_value">
                                        <IconButton disabled>
                                          <KeyboardArrowUpIcon style={{ visibility: "hidden" }} />
                                        </IconButton>
                                        <span
                                          style={{ color: val?.isAccount && "blue" }}
                                          onClick={() => navigateToLedger(val)}
                                        >
                                          {val?.name}
                                        </span>
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {!getApprovedBal ? (
                                        <span
                                          style={{
                                            display: "flex",
                                            justifyContent: "end",
                                            alignItems: "center",
                                            marginRight: "10px",
                                            // paddingRight:
                                            //   row?.amount == undefined || row?.amount == 0 ? "22px" : "0px",
                                          }}
                                        >
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              color:
                                                val?.accountGroupCurrentBalance -
                                                  val?.openingBalance ==
                                                0
                                                  ? "black"
                                                  : val?.accountGroupCurrentBalance -
                                                      val?.openingBalance >
                                                    0
                                                  ? "red"
                                                  : "green",
                                            }}
                                          >
                                            {val?.openingBalance ||
                                            val?.accountGroupCurrentBalance ? (
                                              <>
                                                {showOpeningBalance && (
                                                  <span style={{ color: "#2d269f", padding: "0px 2px" }}>
                                                    <AmountInfo amount={val?.openingBalance} />
                                                    {" | "}{" "}
                                                  </span>
                                                )}
                                                <AmountInfo
                                                  amount={
                                                    val?.accountGroupCurrentBalance -
                                                    val?.openingBalance
                                                  }
                                                />
                                              </>
                                            ) : (
                                              "-"
                                            )}
                                          </span>
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            display: "flex",
                                            justifyContent: "end",
                                            alignItems: "center",

                                            // paddingRight:
                                            //   val?.amount == undefined || val?.amount == 0 ? "22px" : "0px",
                                          }}
                                        >
                                          {" "}
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              marginRight: "10px",
                                              color: val?.approvedAccountGroupCurrentBalance
                                                ? val?.approvedAccountGroupCurrentBalance -
                                                    val?.approvedOpeningBalance ==
                                                  0
                                                  ? "black"
                                                  : val?.approvedAccountGroupCurrentBalance -
                                                      val?.approvedOpeningBalance >
                                                    0
                                                  ? "red"
                                                  : "green"
                                                : val?.approvedClosingBalance
                                                ? val?.approvedClosingBalance -
                                                    val?.approvedOpeningBalance ==
                                                  0
                                                  ? "black"
                                                  : val?.approvedClosingBalance -
                                                      val?.approvedOpeningBalance >
                                                    0
                                                  ? "red"
                                                  : "green"
                                                : "green",
                                              // color:
                                              //   val?.approvedAccountGroupCurrentBalance -
                                              //     val?.approvedOpeningBalance ==
                                              //   0
                                              //     ? "black"
                                              //     : val?.approvedAccountGroupCurrentBalance -
                                              //         val?.approvedOpeningBalance >
                                              //       0
                                              //     ? "red"
                                              //     : "green",
                                            }}
                                          >
                                            {/* {val?.approvedOpeningBalance ||
                                            val?.approvedAccountGroupCurrentBalance ? (
                                              <AmountInfo
                                                amount={
                                                  val?.approvedAccountGroupCurrentBalance -
                                                  val?.approvedOpeningBalance
                                                }
                                              />
                                            ) : (
                                              "-"
                                            )} */}

                                            {val?.approvedAccountGroupCurrentBalance ? (
                                              val?.approvedOpeningBalance ||
                                              val?.approvedAccountGroupCurrentBalance ? (
                                                <>
                                                  {showOpeningBalance && (
                                                    <span style={{ color: "#2d269f", padding: "0px 2px" }}>
                                                      <AmountInfo amount={val?.openingBalance || 0} />
                                                      {" | "}{" "}
                                                    </span>
                                                  )}
                                                  <AmountInfo
                                                    amount={
                                                      val?.approvedAccountGroupCurrentBalance -
                                                      val?.approvedOpeningBalance
                                                    }
                                                  />
                                                </>
                                              ) : (
                                                "-"
                                              )
                                            ) : val?.approvedClosingBalance ? (
                                              val?.approvedOpeningBalance ||
                                              val?.approvedClosingBalance ? (
                                                <>
                                                  {showOpeningBalance && (
                                                    <span style={{ color: "#2d269f", padding: "0px 2px" }}>
                                                      <AmountInfo amount={val?.openingBalance || 0} />
                                                      {" | "}{" "}
                                                    </span>
                                                  )}
                                                  <AmountInfo
                                                    amount={
                                                      val?.approvedClosingBalance -
                                                      val?.approvedOpeningBalance
                                                    }
                                                  />
                                                </>
                                              ) : (
                                                "-"
                                              )
                                            ) : (
                                              "-"
                                            )}
                                          </span>
                                        </span>
                                      )}
                                    </StyledTableCell>
                                    </>
                                    ));

                                  return (
                                    <StyledTableRow key={key} style={style}>
                                      {items}
                                    </StyledTableRow>
                                  );
                                }}
                              />
                            )
                          );
                        }}
                      </AutoSizer>
                    ) : (
                      rowData &&
                      rowData.childList &&
                      rowData.childList.map((childRow, childIndex) => (
                        <Row
                          key={childRow.name}
                          childRow={childRow}
                          index={childIndex + 1}
                          handleRevert={handleRevert}
                          site={site}
                          level={level + 1}
                          item={item}
                          color={"#F5FAFF"}
                          setLoading={setLoading}
                          successAlert={successAlert}
                          errorAlert={errorAlert}
                          getApprovedBal={getApprovedBal}
                          tableData={tableData}
                          setData={setData}
                          totalProfit={totalProfit}
                          navigateToLedger={navigateToLedger}
                          fromDate={fromDate}
                          toDate={toDate}
                          monthId={monthId}
                          showOpeningBalance={showOpeningBalance}
                        />
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function BalanceSheetTable({
  data,
  setData,
  pageNo,
  setPageNo,
  totalPages,
  setPageSize,
  pageSize,
  handleRevert,
  site,
  totalRevenue,
  totalExpenses,
  netAmount,
  setLoading,
  successAlert,
  errorAlert,
  getApprovedBal,
  navigateToLedger,
  fromDate,
  toDate,
  monthId,
  showOpeningBalance,
}) {
  const onPageChange = (event, value) => setPageNo(value);


  // Filter out the "Income" and "Expenses" sections
  const filteredData = Object.keys(data)
    .filter(
      (key) =>
        key === "leftAccountGroupFetchResponseList" ||
        key === "rightAccountGroupFetchResponseList"
    )
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});

  const reversedFilteredData = Object.keys(filteredData)
    .reverse()
    .reduce((obj, key) => {
      obj[key] = filteredData[key];
      return obj;
    }, {});

  const getColorBasedOnSubtraction = (
    dataArray,
    balanceKey,
    openingBalanceKey
  ) => {
    return (
      dataArray &&
      dataArray.map((accountGroup) => {
        const balanceDifference =
          accountGroup[balanceKey] - accountGroup[openingBalanceKey];

        if (balanceDifference === 0) {
          return "black";
        } else if (balanceDifference > 0) {
          return "red";
        } else {
          return "green";
        }
      })
    );
  };
  return (
    <>
      <TableContainer
        // component={Paper}
        style={{ overflow: "hidden" }}
      >
        <Grid container spacing={2}>
          {reversedFilteredData &&
          Object.keys(reversedFilteredData)?.length > 0 ? (
            Object.keys(reversedFilteredData)?.map((item) => {
              return (
                <Grid
                  item
                  md={6}
                  lg={6}
                  style={{
                    maxHeight: "calc(-290px + 100vh)",
                    overflow: "auto",
                  }}
                >
                  <Table aria-label="collapsible table" stickyHeader>
                    <TableHead>
                      <StyledTableRow
                        sx={{ position: "sticky", zIndex: 10000 }}
                      >
                        <StyledTableCell
                          colSpan={12}
                          sx={{ borderRadius: "6px", textAlign: "center" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingLeft: "20px",
                              paddingRight: "6px",
                            }}
                          >
                            <p style={{ textTransform: "capitalize" }}>
                              {reversedFilteredData[item] &&
                                reversedFilteredData[item].length > 0 &&
                                reversedFilteredData[item][0]?.name}
                            </p>
                            <p>
                              Amount(
                              {!getApprovedBal ? (
                                // <AmountInfo
                                //   amount={
                                //     reversedFilteredData[item][0]
                                //       ?.openingBalance -
                                //       reversedFilteredData[item][0]
                                //         ?.accountGroupCurrentBalance || 0
                                //   }
                                // />
                                <>
                                  <AmountInfo
                                    amount={
                                      reversedFilteredData.leftAccountGroupFetchResponseList.reduce(
                                        (total, child) =>
                                          total +
                                          (child?.accountGroupCurrentBalance -
                                            child?.openingBalance),
                                        0
                                      ) + (netAmount < 0 ? netAmount : 0)
                                    }
                                  />
                                </>
                              ) : (
                                <AmountInfo
                                  amount={
                                    reversedFilteredData[item][0]
                                      ?.approvedOpeningBalance -
                                      reversedFilteredData[item][0]
                                        ?.approvedAccountGroupCurrentBalance ||
                                    0
                                  }
                                />
                              )}
                              )
                            </p>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <div style={{ height: "9px" }}></div>
                    {reversedFilteredData?.[item].length > 0 ? (
                      <TableBody>
                        {reversedFilteredData &&
                          reversedFilteredData?.[item]?.map((row, index) => {
                            // Render other rows normally
                            return (
                              <Row
                                key={row.name}
                                childRow={row}
                                index={(pageNo - 1) * pageSize + index + 1}
                                handleRevert={handleRevert}
                                site={site}
                                level={0}
                                item={item}
                                color={"#DDEEFF"}
                                setLoading={setLoading}
                                successAlert={successAlert}
                                errorAlert={errorAlert}
                                getApprovedBal={getApprovedBal}
                                tableData={data}
                                setData={setData}
                                totalProfit={
                                  getApprovedBal
                                    ? data?.netApprovedBalance
                                    : data?.netBalance
                                }
                                navigateToLedger={navigateToLedger}
                                fromDate={fromDate}
                                toDate={toDate}
                                monthId={monthId}
                                showOpeningBalance={showOpeningBalance}
                              />
                            );
                          })}

                        {netAmount < 0 &&
                        item === "leftAccountGroupFetchResponseList" ? (
                          <tr
                            style={{
                              cursor: "pointer",
                              backgroundColor: `#DDEEFF`,
                              marginTop: "1rem!important",
                              border: "2px solid red",
                            }}
                          >
                            <TableCell style={{ padding: "12px" }} colSpan={6}>
                              <Typography
                                variant="small1"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span
                                  style={{ color: "red", paddingLeft: "30px" }}
                                >
                                  Loss
                                </span>
                                <span>
                                  <AmountInfo amount={netAmount} />
                                </span>
                              </Typography>
                            </TableCell>
                          </tr>
                        ) : netAmount >= 0 &&
                          item === "rightAccountGroupFetchResponseList" ? (
                          <tr
                            sx={{
                              cursor: "pointer",
                              backgroundColor: `#DDEEFF`,
                              marginTop: "1rem!important",
                              border: "2px solid red",
                            }}
                          >
                            <TableCell
                              colSpan={6}
                              style={{
                                padding: "12px",
                                backgroundColor: `#DDEEFF`,
                              }}
                            >
                              <Typography
                                variant="small1"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span
                                  style={{
                                    color: "green",
                                    paddingLeft: "30px",
                                  }}
                                >
                                  Profit
                                </span>
                                <span>
                                  <AmountInfo amount={netAmount} />
                                </span>
                              </Typography>
                            </TableCell>
                          </tr>
                        ) : null}

                        {/* Rest of your code */}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell
                            className="tableData"
                            colSpan={12}
                            sx={{ textAlign: "center" }}
                          >
                            No Data to Show
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    )}
                  </Table>
                </Grid>
              );
            })
          ) : (
            <>
              <Grid item md={6} lg={6}>
                <Table aria-label="collapsible table" stickyHeader>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell colSpan={12}>Revenue</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        colSpan={12}
                        sx={{ textAlign: "center" }}
                      >
                        No Data to Show
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item md={6} lg={6}>
                <Table aria-label="collapsible table" stickyHeader>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell colSpan={12}>Expenses</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        colSpan={12}
                        sx={{ textAlign: "center" }}
                      >
                        No Data to Show
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Grid>
            </>
          )}
        </Grid>
      </TableContainer>
      <Grid container spacing={2}>
        <Grid item md={6} lg={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "6px",
              background: "#f3a0a0",
              height: "38px",
              alignItems: "center",
              padding: "0px 15px",
              fontWeight: "600",
              marginTop: "1rem",
            }}
          >
            <p>Total Assets</p>
            {!getApprovedBal ? (
              <p
                style={{
                  color: getColorBasedOnSubtraction(
                    reversedFilteredData?.leftAccountGroupFetchResponseList,
                    "accountGroupCurrentBalance",
                    "openingBalance"
                  ),
                }}
              >
                {reversedFilteredData?.leftAccountGroupFetchResponseList && (
                  <AmountInfo
                    amount={
                      reversedFilteredData.leftAccountGroupFetchResponseList.reduce(
                        (total, child) =>
                          total +
                          (child?.accountGroupCurrentBalance -
                            child?.openingBalance),
                        0
                      ) + (netAmount < 0 ? netAmount : 0)
                    }
                  />
                )}
              </p>
            ) : (
              <p
                style={{
                  color: getColorBasedOnSubtraction(
                    reversedFilteredData?.leftAccountGroupFetchResponseList,
                    "approvedAccountGroupCurrentBalance",
                    "approvedOpeningBalance"
                  ),
                }}
              >
                {reversedFilteredData?.leftAccountGroupFetchResponseList && (
                  <AmountInfo
                    amount={
                      reversedFilteredData.leftAccountGroupFetchResponseList.reduce(
                        (total, child) =>
                          total +
                          (child?.approvedAccountGroupCurrentBalance -
                            child?.approvedOpeningBalance),
                        0
                      ) + (netAmount < 0 ? netAmount : 0)
                    }
                  />
                )}
              </p>
            )}
          </div>
        </Grid>
        <Grid item md={6} lg={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "6px",
              textAlign: "center",
              fontWeight: 600,
              backgroundColor: "#a7f3a7",
              height: "38px",
              alignItems: "center",
              padding: "0px 15px",
              fontWeight: "600",
              marginTop: "1rem",
            }}
          >
            <p>Total Liabilities</p>
            {!getApprovedBal ? (
              <p
                style={{
                  color: getColorBasedOnSubtraction(
                    reversedFilteredData?.rightAccountGroupFetchResponseList,
                    "accountGroupCurrentBalance",
                    "openingBalance"
                  ),
                }}
              >
                {reversedFilteredData?.rightAccountGroupFetchResponseList && (
                  <AmountInfo
                    amount={
                      reversedFilteredData.rightAccountGroupFetchResponseList.reduce(
                        (total, child) =>
                          total +
                          (child?.accountGroupCurrentBalance -
                            child?.openingBalance),
                        0
                      ) + (netAmount > 0 ? netAmount : 0)
                    }
                  />
                )}
              </p>
            ) : (
              <p
                style={{
                  color: getColorBasedOnSubtraction(
                    reversedFilteredData?.rightAccountGroupFetchResponseList,
                    "approvedAccountGroupCurrentBalance",
                    "approvedOpeningBalance"
                  ),
                }}
              >
                {reversedFilteredData?.rightAccountGroupFetchResponseList && (
                  <AmountInfo
                    amount={
                      reversedFilteredData.rightAccountGroupFetchResponseList.reduce(
                        (total, child) =>
                          total +
                          (child?.approvedAccountGroupCurrentBalance -
                            child?.approvedOpeningBalance),
                        0
                      ) + (netAmount > 0 ? netAmount : 0)
                    }
                  />
                )}
              </p>
            )}
          </div>
        </Grid>
      </Grid>

      <ColorInfo />

      <Divider />
    </>
  );
}
