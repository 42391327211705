import { useEffect, useState } from "react";

export const validationFunction = (
  customFormData,
  nextState,
  updating,
  isInvoiceMandatory,
  isAccountCode,
  isTransactionType,
  referenceNo,
  selectTransactionType,
  selectAccountCode
) => {
  let errors = {};

  if (
    customFormData &&
    Array.isArray(customFormData) &&
    customFormData.length > 0
  ) {
    for (const val of customFormData) {
      const { label, isRequired, value } = val;

      if (isRequired) {
        if (!value) errors[label] = true;
      }
    }
  }

  if (isAccountCode === true) {
    if (!selectAccountCode) {      
      errors.accountCode = true;
    }
  }
  if (isTransactionType === true) {
    if(!selectTransactionType){
      errors.transactionType = true;
    }
  }
  if (isInvoiceMandatory === true) {
    if(!referenceNo){
      errors.invoiceNumber = true;
    }
  }

  if (!updating) {
    if (!nextState) {
      errors.nextState = true;
    }
  }

  return errors;
};

export const headers = [
  { id: 15, name: "Select All", alias: "all" },
  { id: 16, name: "Remove All", alias: "remove" },
  { id: 0, name: " ", width: "60", textAlign: "left" },
  { id: 1, name: "S.NO", width: "100", textAlign: "left" },
  { id: 2, name: "Transaction Date", width: "200", textAlign: "left" },
  { id: 3, name: "Txn Id", width: "100", textAlign: "left" },
  { id: 4, name: "Debit Account", width: "300", textAlign: "left" },
  { id: 5, name: "Credit Account", width: "300", textAlign: "left" },
  { id: 6, name: "Amount", width: "120", textAlign: "right" },
  { id: 14, name: "Voucher", width: "150", textAlign: "left" },
  { id: 7, name: "Created On", width: "300", textAlign: "left" },
  { id: 8, name: "Created By", width: "300", textAlign: "left" },
  { id: 9, name: "Status", width: "180", textAlign: "left" },
];

export const newHeaders = [
  {
    id: 17,
    name: "Debit Account Closing Balance",
    alias: "sourceAccountClosingBalance",
  },
  {
    id: 18,
    name: "Debit Account Opening Balance",
    alias: "sourceAccountOpeningBalance",
  },
  {
    id: 19,
    name: "Credit Account Closing Balance",
    alias: "targetAccountClosingBalance",
  },
  {
    id: 20,
    name: "Credit Account Opening Balance",
    alias: "targetAccountOpeningBalance",
  },
  { id: 10, name: "CGST", width: "180", textAlign: "right" },
  { id: 11, name: "IGST", width: "180", textAlign: "right" },
  { id: 12, name: "GST", width: "180", textAlign: "right" },
  { id: 13, name: "Total Amount", width: "180", textAlign: "right" },
];

export const updatedHeaders = [
  { id: 1, name: "S.NO" },
  { id: 2, name: "Transaction Date" },
  { id: 3, name: "Txn Id" },
  { id: 4, name: "Debit Account" },
  { id: 5, name: "Credit Account" },
  { id: 6, name: "Amount" },
  { id: 7, name: "Created On" },
  { id: 8, name: "Debit Narration" },
  { id: 9, name: "Credit Narration" },
  { id: 10, name: "Created By" },
  // { id: 9, name: "Action" }',
  // { id: 10, name: "Total Amount" },
];

export const modalHeaders = [
  { id: 1, name: "S.NO" },
  { id: 2, name: "Transaction Date" },
  { id: 3, name: "Txn Id" },
  { id: 4, name: "Debit Account" },
  { id: 5, name: "Credit Account" },
  { id: 6, name: "Amount" },
  // { id: 11, name: "Voucher" },
  // { id: 7, name: "Created On" },
  // { id: 8, name: "Created By" },
  // { id: 9, name: "Action" },
  { id: 11, name: "Status" },
  { id: 12, name: "Reason" },
  { id: 10, name: "Total Amount" },

  // { id: 12, name: "Action" },
];

export const paymentStatusList = [
  { id: 1, name: "Pending", alias: "PENDING" },
  { id: 2, name: "Partial", alias: "PARTIAL" },
  { id: 3, name: "Completed", alias: "COMPLETED" },
];


export const useLocalStorage = (key) => {
  const [storedValue, setStoredValue] = useState(() => localStorage.getItem(key));

  useEffect(() => {
      const handleStorageChange = () => {
          setStoredValue(localStorage.getItem(key));
      };

      window.addEventListener('storage', handleStorageChange);
      
      // Cleanup listener on unmount
      return () => {
          window.removeEventListener('storage', handleStorageChange);
      };
  }, [key]);

  return storedValue;
};