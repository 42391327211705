// import axios from "axios";
// import config from "../config";
// import { getHeaders } from "../Network_Utility";

// const axiosInstance = axios.create({
//   baseURL: config.baseUrl,
// });

// axiosInstance.interceptors.request.use(async (config) => {
//   const options = await getHeaders();
//   config.headers = { ...config.headers, ...options?.headers };
//   return config;
// });

// export const userService = {
//   getIdentifier: (apiEndpoint, payload) =>
//     axiosInstance.get(config.baseUrl + apiEndpoint, payload),

//   login: async (apiEndpoint, payload) => {
//     try {
//       const response = await axiosInstance.post(
//         config.loginUrl + apiEndpoint,
//         payload
//       );
//       return response;
//     } catch (error) {
//       console.error("Error in POST request:", error);
//       throw error;
//     }
//   },

//   get: (apiEndpoint, payload) => axiosInstance.get(apiEndpoint, payload),

//   postData: async (apiEndpoint, payload, key) => {

//     const headers = { Accountkey: key };
//     return axiosInstance.post(apiEndpoint, payload, { headers });
//   },
//   patchData: (apiEndpoint, payload) => axiosInstance.patch(apiEndpoint, payload),
//   getData: (apiEndpoint) => axiosInstance.get(apiEndpoint),

//   getSites: (apiEndpoint) =>
//     axiosInstance.get(config.baseUrl + apiEndpoint),

//   getUserPermission: (apiEndpoint, payload) =>
//     axiosInstance.post(config.permissionUrl + apiEndpoint, payload),

//   getPermissionCombination: (apiEndpoint, payload) =>
//     axiosInstance.post(config.permissionUrl + apiEndpoint, payload),

//   getReports: async (apiEndpoint, payload, fileName) => {
//     const options = await getHeaders();
//     var res = axios({
//       method: "post",
//       url: config.baseUrl + apiEndpoint,
//       data: payload,
//       headers: options?.headers
//     })
//       .then((res) => {
//         if (res && res.data && res.data.status === 200 && res.data.data) {
//           let pdf = res?.data?.data;
//           const linkSource = `data:application/pdf;base64,${pdf}`;
//           const downloadLink = document.createElement("a");
//           downloadLink.href = linkSource;
//           downloadLink.download = fileName;
//           downloadLink.click();
//         }
//         return res;
//       })
//       .catch((err) => err);
//     return res;
//   },

//   downloadTemplate: async (apiEndpoint, payload, name) => {
//     let url = `${config.baseUrl}accounting/excel-util/v1/get-template`
//     try {
//       const options = await getHeaders(); // Wait for getHeaders to resolve

//       const response = await axios({
//         method: "post",
//         url: url,
//         data: payload,
//         headers: options?.headers,
//       });

//       if (response && response.data && response.data.status === 200) {
//         const pre =
//           "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
//         const anchor = document.createElement("a");
//         anchor.href = pre + response.data.data;
//         anchor.style.display = "block";
//         anchor.setAttribute("download", name);
//         anchor.click();
//       }

//       return response;
//     } catch (error) {
//       console.error("Error downloading template:", error);
//       // Handle the error as needed
//       throw error;
//     }
//   }
// };

import axios from "axios";
import config from "../config";
import { getHeaders } from "../Network_Utility";

const axiosInstance = axios.create({
  baseURL: config.baseUrl,
});

axiosInstance.interceptors.request.use(async (config) => {
  const options = await getHeaders();
  config.headers = { ...config.headers, ...options?.headers };
  return config;
});

let lastRequest = {
  endpoint: "",
  payload: "",
  key: "",
};
export const userService = {
  getIdentifier: (apiEndpoint, payload) =>
    axiosInstance.get(config.identifierUrl + apiEndpoint, payload),

  login: async (apiEndpoint, payload) => {
    try {
      const response = await axiosInstance.post(
        config.loginUrl + apiEndpoint,
        payload
      );
      return response;
    } catch (error) {
      console.error("Error in POST request:", error);
      throw error;
    }
  },

  get: (apiEndpoint, payload) => {
    lastRequest = {
      endpoint: "",
      payload: "",
      key: "",
    };
    return axiosInstance.get(apiEndpoint, payload)
  },

  postData: async (apiEndpoint, payload, key, isAllowReq) => {
    if (
      isAllowReq !== true &&
      lastRequest.endpoint === apiEndpoint &&
      lastRequest.payload === JSON.stringify(payload) &&
      lastRequest.key === key
    ) {
      return;
    }

    // Update the last request
    lastRequest = {
      endpoint: apiEndpoint,
      payload: JSON.stringify(payload),
      key: key,
    };
    const headers = { Accountkey: key };
    const fetchData = axiosInstance.post(apiEndpoint, payload, { headers });
    return fetchData;
  },
  patchData: (apiEndpoint, payload) =>
    axiosInstance.patch(apiEndpoint, payload),
  getData: (apiEndpoint) => {
    lastRequest = {
      endpoint: "",
      payload: "",
      key: "",
    };
   return axiosInstance.get(apiEndpoint)
  },

  getSites: (apiEndpoint) =>{
    lastRequest = {
      endpoint: "",
      payload: "",
      key: "",
    };
    return axiosInstance.get(config.baseUrl + apiEndpoint)
  },

  getUserPermission: (apiEndpoint, payload) => {
    lastRequest = {
      endpoint: "",
      payload: "",
      key: "",
    };
   return axiosInstance.post(config.permissionUrl + apiEndpoint, payload)
  },

  getPermissionState: (apiEndpoint, payload) => {
    lastRequest = {
      endpoint: "",
      payload: "",
      key: "",
    };
   return axiosInstance.post(config.permissionStateUrl + apiEndpoint, payload)
  },

  getPermissionCombination: (apiEndpoint, payload) =>{
    lastRequest = {
      endpoint: "",
      payload: "",
      key: "",
    };
   return axios.post(config.permissionUrl + apiEndpoint, payload)
  },

  getReports: async (apiEndpoint, payload, fileName) => {
    lastRequest = {
      endpoint: "",
      payload: "",
      key: "",
    };
    const options = await getHeaders();
    var res = axios({
      method: "post",
      url: config.baseUrl + apiEndpoint,
      data: payload,
      headers: options?.headers,
    })
      .then((res) => {
        if (res && res.data && res.data.status === 200 && res.data.data) {
          let pdf = res?.data?.data;
          const linkSource = `data:application/pdf;base64,${pdf}`;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
        return res;
      })
      .catch((err) => err);
    return res;
  },

  downloadTemplate: async (apiEndpoint, payload, name) => {
    lastRequest = {
      endpoint: "",
      payload: "",
      key: "",
    };
    let url = `${config.baseUrl}/excel-util/v1/get-template`;
    try {
      const options = await getHeaders(); // Wait for getHeaders to resolve

      const response = await axios({
        method: "post",
        url: url,
        data: payload,
        headers: options?.headers,
      });

      if (response && response.data && response.data.status === 200) {
        const pre =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const anchor = document.createElement("a");
        anchor.href = pre + response.data.data;
        anchor.style.display = "block";
        anchor.setAttribute("download", name);
        anchor.click();
      }

      return response;
    } catch (error) {
      console.error("Error downloading template:", error);
      // Handle the error as needed
      throw error;
    }
  },
};
