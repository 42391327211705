import { getEncryptPayload } from "../../Network_Utility";
import { userPermissionUrl } from "../../config";
import { userService } from "../../userService";
import { fetchSiteList } from "./SitesAction";

const PERMISSION_FETCH_SUCCESS = "PERMISSION_FETCH_SUCCESS";
const PERMISSION_FETCH_FAILURE = "PERMISSION_FETCH_FAILURE";
const sidebarMenus = [
  {
    name: "Dashboard",
    path: "/dashboard"
  },
  {
    name: "Chart Of Accounts",
    path: "/account"
  },
  {
    name: "Reports",
    path: null
  },
  {
    name: "Daybook",
    path: "/daybook"
  },
  {
    name: "Trial Balance",

    path: "/trialBalance"
  },
  {
    name: "Bank Book",
    path: "/bankBook"
  },
  {
    name: "Fixed Assets",

    path: "/fixedAssets"
  },
  {
    name: "Liabilities",

    path: "/liabilities"
  },
  {
    name: "P&L Statement",

    path: "/profitLoss"
  },
  {
    name: "Balance Sheet",

    path: "/balanceSheet"
  },
  {
    name: "Annual Balance Sheet",

    path: "/annualbalanceSheet"
  },
  {
    name: "Annual Profit & Loss",

    path: "/annualprofitandloss"
  },

  {
    name: "Cash Flow",

    path: "/cashflow"
  },
  {
    name: "Master Management",
    path: null
  },
  {
    name: "Account Type",

    path: "/accountType"
  },
  {
    name: "Account Group",

    path: "/accountGroup"
  },

  {
    name: "Report Group",

    path: "/reportGroup"
  },

  {
    name: "KYC Implementation",

    path: "/kycImplemenation"
  },

  {
    path: "/preferences",
    name: "Preferences"
  },
  {
    name: "Voucher",
    path: "/voucherTemplate"
  },
  {
    name: "Voucher Template",
    path: "/voucherTemplate"
  },
  {
    // name: "Vendor Template",
    name: "Third Party Api Integration",
    path: "/vendorTemplate"
  },
  {
    name: "Reports Configurations",
    path: "/reportsMaster"
  },
  {
    name: "Narration Configuration",
    path: "/narration"
  },
  {
    name: "Account Code",
    path: "/account-code"
  },
  {
    name: "Account Transaction Type",
    path: "/account-transaction-type"
  },
  {
    name: "Due Days",
    path: "/addTransaction"
  },
  {
    name: "Voucher PDF",
    path: "/addTransaction"
  },
  {
    name: "Copy Transaction",
    path: "/daybook"
  }
];

export function fetchUserPermission(
  setIsLoading,
  errorAlert,
  roleId,
  primarySiteId,
  navigate
) {
  return (dispatch) => {
    const payload = {
      id: 21,
      isActive: true,
      key: "ACCOUNTKEY"
    };
    const encryptedPayload = getEncryptPayload(payload, true);
    setIsLoading(true);

    userService
      .getUserPermission(userPermissionUrl, encryptedPayload)
      .then((res) => {
        if (res?.data?.data) {
          const updatedData = res.data.data.map((item) => {
            const matchedMenu = sidebarMenus.find(
              (menu) => menu.name === item.name
            );

            if (matchedMenu) {
              item.path = matchedMenu.path;
            }

            return item;
          });

          dispatch(permissionRequestSuccess(updatedData));
          const encryptedPayload = getEncryptPayload(payload, true);
          setIsLoading(true);

          setTimeout(() => {
            dispatch(
              fetchSiteList(
                setIsLoading,
                errorAlert,
                primarySiteId,
                navigate,
                updatedData
              )
            );
          }, 100);
        } else {
          dispatch(permissionRequestFailure(res?.data));
          errorAlert(res?.data?.message || "Something went wrong");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        errorAlert("Something went wrong");
        setIsLoading(false);
        setIsLoading(false);
      });
  };
}

export const permissionRequestFailure = (data) => {
  return {
    type: PERMISSION_FETCH_FAILURE,
    data: data
  };
};
export const permissionRequestSuccess = (data) => {
  return {
    type: PERMISSION_FETCH_SUCCESS,
    data: data
  };
};
