import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Paper,
  Button,
  Container,
  Grid,
  TextField,
  Autocomplete,
  IconButton,
  CardContent,
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { userService } from "../userService";
import { disableSite } from "../features/actions/siteAction";
import { AlertBar } from "../Alert/AlertBar";
import { getEncryptPayload } from "../Network_Utility";
import {
  accountGroupDropdownUrl,
  accountGroupDropdownUrlV2,
  addTransactionUrl,
  filterAccountUrl,
  getAllState,
  voucherTypeSaveUrl,
  accountGroupDropdownUrlVoucher,
  accountTypesV2Url,
  accountTypesV1Url,
} from "../config";
import { styles } from "../Theme/Theme";
import { Card, Typography } from "@material-ui/core";
import {
  accountTypeConfigValidation,
  templateViewList,
  validateCustomFields,
} from "./util";
import { useSelector } from "react-redux";
import { checkPathExist, checkPermissionExist } from "../utils";
import { themecolor } from "../Navigation";
import { v4 as uuidv4 } from "uuid";
import { FcSettings } from "react-icons/fc";
import DragAndDropComponent from "./DynamicFields";
import TableComponentModal from "./TableComponentModal";
import Editor from "./Editor";
import LoadingComponent from "../Loader";
import ClearIcon from "@mui/icons-material/Clear";
import Header from "../CommonComps/Header";
import ConfigModal from "./ConfigModal";
import {
  ChildAccountConfiguration,
  ConfigurationCheckFields,
  DetailsViewConfigurations,
  DueDataConfigurations,
  MandatoryCheck,
  TaggedFunction,
} from "./CollapsableData";
import { getAllAccountTypes } from "../features/actions/AccountTypeAction";
const customFieldIcon = require("../images/custom.png");

// const templateViewList = [
//   { id: 1, name: "Journal", alias: "JOURNAL" },
//   { id: 2, name: "Purchase", alias: "PURCHASE" },
//   { id: 3, name: "Sale", alias: "SALE" }
// ];

const CreateVoucherTemp = ({ classes }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state?.rootReducer);
  const siteList = reduxState.sitesReducer?.data;
  const endPoints = reduxState?.permissionReducer?.data;
  const accountTypesState = reduxState.accountTypeReducer;

  // ==================States=================//
  const [loading, setLoading] = useState(false);
  const [accGrps, setAccGrps] = useState([]);
  const [accountGroups, setAccountGroups] = useState([]);
  const [debitAccounts, setDebitAccounts] = useState([]);
  const [creditAccounts, setCreditAccounts] = useState([]);
  const [vendorName, setVendorName] = useState(null);
  const [customErrors, setCustomErrors] = useState({});
  const [tableItem, setTableItem] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [dynamicFields, setDynamicFields] = React.useState([]);
  const [openEditor, setOpenEditor] = useState(false);
  const [editedIndex, seteditedIndex] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [stateActionList, setStateActionList] = useState([]);
  const [nextState, setNextState] = useState(null);
  const [accountTypes, setAccountTypes] = useState([]);
  const [targetAccountTypes, setTargetAccountTypes] = useState([]);

  // ===========Templates Confuiguration state's========//
  const [selectTemplateView, setSelectTemplateView] = useState(null);
  const [showTemplateView, setTemplateView] = useState(false);

  const [overDueBoolean, setOverDueBoolean] = useState(false);
  const [accountCategory, setAccountCategory] = useState(false);
  const [overDueDays, setOverDueDays] = useState(0);
  const [checkboxError, setCheckboxError] = React.useState(false);
  const [radioError, setRadioError] = React.useState(false);

  const [taggedInvoice, setTaggedInvoice] = useState(false);

  const [invoiceOrNoteNumber, setInvoiceorNotNumber] = useState(false);
  const [accountCode, setAccountCode] = useState(false);
  const [transactionType, setTransactionType] = useState(false);

  const [accountTypeChildLists, setAccountTypeChildLists] = useState([]);
  const [sourceAccountType, setSourceAccountType] = useState(null);
  const [targetAccountType, setTargetAccountType] = useState(null);
  const [sourceAccountTypeChildLists, setSourceAccountTypeChildLists] =
    useState([]);
  const [sourceAccountTypeChildList, setSourceAccountTypeChildList] =
    useState(null);
  const [targetAccountTypeChildLists, setTargetAccountTypeChildLists] =
    useState([]);
  const [targetAccountTypeChildList, setTargetAccountTypeChildList] =
    useState(null);
  const [isSourceValidation, setIsSourceValidation] = useState(true);
  const [childConfigError, setChildConfigError] = useState({});
  const [sourceAccountChildTableList, setSourceAccountChildTableList] =
    useState([]);
  const [targetAccountChildTableList, setTargetAccountChildTableList] =
    useState([]);

  // const [mappedStrings, setMappedString] = useState([])
  // ===========Alerts===================//
  const [alerts, setAlerts] = useState({
    visible: false,
    alertMessage: "",
    alertType: "",
  });

  // Function to close alert bar
  const closeAlertBar = () => setAlerts({ ...alerts, visible: false });

  // Function to show success alert
  const successAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Data fetched succesfully",
      alertType: "success",
    });
    setLoading(false);
    setTimeout(() => {
      navigate("/voucherTemplate");
    }, 1000);
  };

  // Function to show error
  const errorAlert = (message) => {
    setAlerts({
      visible: true,
      alertMessage: message || "Something went wrong",
      alertType: "error",
    });
    setLoading(false);
  };

  //Function to edit created fields
  const openEditorFuntion = (index) => {
    setOpenEditor(true);
    seteditedIndex(index);
  };

  //Function to close created fields modal
  const closeEditor = () => setOpenEditor(false);

  // Function to open table modal
  const setHandleTableComponent = (item) => {
    setOpenModal(true);
    setTableItem(item);
  };

  // Function to close table modal
  const closeDialogBox = () => setOpenModal(false);

  // const addMappedString = (e) => setMappedString([...mappedStrings, e.target.value])

  function hasDuplicateNames(arr) {
    const nameSet = new Set();
    for (const obj of arr) {
      if (nameSet.has(obj.label)) {
        return true; // Duplicate label found
      }
      nameSet.add(obj.label);
    }
    return false; // No duplicate names found
  }

  //Function to save template
  const handlesaveTemplate = async () => {
    const textFieldErrors = validateCustomFields(
      vendorName,
      creditAccounts,
      debitAccounts,
      selectTemplateView,
      overDueBoolean,
      accountCategory,
      overDueDays,
      taggedInvoice,
      invoiceOrNoteNumber,
      accountCode,
      transactionType
    );

    setCustomErrors(textFieldErrors);

    if (Object.keys(textFieldErrors).length < 1) {
      if (hasDuplicateNames(dynamicFields)) {
        errorAlert("Template contains fields with similar label.");
      } else {
        const payload = {
          customFormType: vendorName,
          companyId: localStorage.getItem("companyId"),
          debitGroupIds: debitAccounts?.length
            ? debitAccounts.map((val) => val.id)
            : null,
          creditGroupIds: creditAccounts?.length
            ? creditAccounts.map((val) => val.id)
            : null,
          siteId: localStorage.getItem("siteId"),
          customFormData: { templateData: dynamicFields },
          permissionStateId: nextState?.stateId || null,
          isAvailable: nextState?.isFinal || false,
          voucherCategoryType:
            overDueBoolean === true
              ? "SALE_PURCHASE_NOTE"
              : taggedInvoice
              ? "PAYMENT"
              : "OTHER",
          voucherTempalteCategoryId: selectTemplateView?.id || null,
          isOverdueForDebit:
            accountCategory === "debit" || taggedInvoice === "debit"
              ? true
              : accountCategory === "credit" || taggedInvoice === "credit"
              ? false
              : null,
          defaultOverdueDays: overDueDays > 0 ? overDueDays : null,
          // taggedInvoice: taggedInvoice || null,
          isInvoiceMandatory: invoiceOrNoteNumber || false,
          isAccountCode: accountCode || null,
          isTransactionType: transactionType || null,
          isSourceChildAccountType:
            sourceAccountChildTableList?.length > 0 ? true : false,
          isTargetChildAccountType:
            targetAccountChildTableList?.length > 0 ? true : false,
          sourceChildAccountTypeDtoList: sourceAccountChildTableList?.map(
            (ele) => {
              return {
                customFormTypeId: null,
                childAccountTypeId: ele?.child?.id,
                isActive: true,
                createdBy: +localStorage.getItem("userId"),
                updatedBy: +localStorage.getItem("userId"),
                isForSource: true,
                accountTypeId: ele?.type?.id,
              };
            }
          ),
          targetChildAccountTypeDtoList: targetAccountChildTableList?.map(
            (ele) => {
              return {
                customFormTypeId: null,
                childAccountTypeId: ele?.child?.id,
                isActive: true,
                createdBy: +localStorage.getItem("userId"),
                updatedBy: +localStorage.getItem("userId"),
                isForSource: false,
                accountTypeId: ele?.type?.id,
              };
            }
          ),
        };

        const encryptedPayload = getEncryptPayload(payload, true);

        try {
          setLoading(true);
          const response = await userService.postData(
            voucherTypeSaveUrl,
            encryptedPayload
          );
          if (response && response.data && response.data.status === 200) {
            successAlert(response?.data?.message);
          } else errorAlert(response?.data?.message);
        } catch (error) {
          errorAlert();
        }
      }
    }
  };

  const handleAccountChange = (event, newValue, accountType) => {
    if (!newValue) return;

    const targetAccounts =
      accountType === "debit" ? creditAccounts : debitAccounts;

    if (accountType === "debit") {
      setDebitAccounts([...debitAccounts, newValue]);
    } else setCreditAccounts([...creditAccounts, newValue]);

    // const isPresent = targetAccounts && targetAccounts.some(val => val.id === newValue.id);

    // if (isPresent) {
    //     errorAlert(`Selected account group is already present in ${accountType === 'debit' ? 'credit' : 'debit'} account group.`);
    // } else {
    //     if (accountType === 'debit') setDebitAccounts([...debitAccounts, newValue]);
    //     else setCreditAccounts([...creditAccounts, newValue]);
    // }
  };

  // Debounce function
  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  // Debounced event handler
  const debounceData = debounce(async (event, value) => {
    const payload = { siteId: null, searchField: event.target.value };

    if (event.target.value) {
      const valString = event.target.value;
      if (valString.length > 2) {
        setLoading(true);
        try {
          const encryptedPayload = getEncryptPayload(payload, true);

          const res = await userService.postData(
            accountGroupDropdownUrlVoucher,
            encryptedPayload
          );

          if (res?.data?.status === 200) {
            const { data } = res.data;

            const filteredAccgroup = (data || []).filter(
              (val) => val.siteId != null
            );

            const arrayOfGroup = filteredAccgroup
              .map((group) => {
                const siteMatch = siteList.find(
                  (site) => site.id === group.siteId
                );
                if (siteMatch) {
                  return {
                    ...group,
                    siteName: siteMatch.name,
                  };
                }
                return null;
              })
              .filter(Boolean);

            setAccountGroups(arrayOfGroup);
            // setDebitAccounts([]);
            // setCreditAccounts([]);
            setLoading(false);
          } else {
            setLoading(false);
            setAccountGroups([]);
            // setDebitAccounts([]);
            // setCreditAccounts([]);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    } else {
      // setAccountGroups([]);
      // setDebitAccounts([]);
      // setCreditAccounts([]);
    }
  }, 300); // Adjust debounce time as needed

  // Function to fetch account groups
  const getAccountGroup = async () => {
    try {
      const payload = { siteId: null };
      const encryptedPayload = getEncryptPayload(payload, true);

      const res = await userService.postData(
        accountGroupDropdownUrlVoucher,
        encryptedPayload
      );

      if (res?.data?.status === 200) {
        const { data } = res.data;

        const filteredAccgroup = (data || []).filter(
          (val) => val.siteId != null
        );

        const arrayOfGroup = filteredAccgroup
          .map((group) => {
            const siteMatch = siteList.find((site) => site.id === group.siteId);
            if (siteMatch) {
              return {
                ...group,
                siteName: siteMatch.name,
              };
            }
            return null;
          })
          .filter(Boolean);

        setAccountGroups(arrayOfGroup);
        setDebitAccounts([]);
        setCreditAccounts([]);
      } else {
        setAccountGroups([]);
        setDebitAccounts([]);
        setCreditAccounts([]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    dispatch(disableSite(false));
    // getAccountGroup();
    // eslint-disable-next-line
  }, []);

  const filterAccountGroups = (inputValue, type) => {
    if (!inputValue) {
      setAccGrps([]);
      return;
    }

    const filteredGrps = accountGroups.filter((val) =>
      val.name.toLowerCase().includes(inputValue.toLowerCase())
    );

    if (filteredGrps && filteredGrps.length > 0) {
      setAccGrps(filteredGrps);
    }
    // else {
    //   type == "credit"
    //     ? setCustomErrors({
    //         ...customErrors,
    //         creditAccounts: "No accounts found",
    //       })
    //     : setCustomErrors({
    //         ...customErrors,
    //         debitAccounts: "No accounts found",
    //       });
    // }
  };

  const handleKeyPress = (e, type) => {
    filterAccountGroups(e.target.value, type);
  };

  const getPermissionCombinationList = async (url) => {
    const payload = {
      permissionId:
        endPoints && endPoints.find((val) => val.apiEndpoint === `/${url}`)?.id,
      permissionStateId: null,
      isActive: true,
      roleId: 20,
    };

    const encryptedPayload = getEncryptPayload(payload, true);

    try {
      const res = await userService.getPermissionCombination(
        getAllState,
        encryptedPayload
      );
      if (res && res.data && res.data.status) {
        let data = res?.data?.data.map((val) => ({
          ...val,
          stateId: val?.stateId?.id,
          name: val?.stateId?.name,
        }));
        setStateActionList(data);
        if (data && data.length > 0) {
          setNextState(data[0]);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getPermissionCombinationList(addTransactionUrl);
  }, []);

  // Function to get account  types list
  // Function to get account  types list
  const getAccountTypes = async () => {
    const permissionExist = checkPermissionExist(endPoints, accountTypesV1Url);
    // const isPath = checkPathExist(endPoints, window.location.pathname);
    if (permissionExist) {
      let payload = {isChildAccountCreationNeeded: true};
      let encryptedPayload = getEncryptPayload(payload, true);
      dispatch(getAllAccountTypes(encryptedPayload));
    } else {
      errorAlert("Permission Denied for account type");
    }
  };

  useEffect(() => {
    setAccountTypes(accountTypesState?.data);
    setTargetAccountTypes(accountTypesState?.data);
  }, [accountTypesState]);

  useEffect(() => {
    getAccountTypes();
  }, []);

  const addSourceAccountChildHandle = () => {
    // Validate the input fields
    const isValidate = accountTypeConfigValidation(
      true,
      sourceAccountType,
      sourceAccountTypeChildList
    );
    setChildConfigError(isValidate);

    if (Object.keys(isValidate).length === 0) {
      // Check if an item with the same name or suffixName already exists
  
      // If no such item exists, add the new item
      const obj = {
        type: sourceAccountType,
        child: sourceAccountTypeChildList,
        id: uuidv4(),
      };
      setSourceAccountChildTableList([...sourceAccountChildTableList, obj]);
      const removeFromList =
        accountTypes &&
        accountTypes.length > 0 &&
        accountTypes.filter((ele) => ele.id !== sourceAccountType.id);
      setAccountTypes(removeFromList);
      setSourceAccountType(null);
      setSourceAccountTypeChildList(null);
    }
  };

  const deleteSourceAccountChildHandle = (id) => {
    if (id) {
      const findDeletedData = accountTypesState?.data?.find(
        (ele) => ele.id === id?.type?.id
      );
      setAccountTypes([...accountTypes, findDeletedData]);
      const findDeletedDataList = sourceAccountChildTableList?.filter(
        (ele) => ele.id !== id?.id
      );
      setSourceAccountChildTableList(findDeletedDataList);
    }
  };

  const addTargetAccountChildHandle = () => {
    // debugger
    // Validate the input fields
    const isValidate = accountTypeConfigValidation(
      false,
      targetAccountType,
      targetAccountTypeChildList
    );
    setChildConfigError(isValidate);

    if (Object.keys(isValidate).length === 0) {
      // If no such item exists, add the new item
      const obj = {
        type: targetAccountType,
        child: targetAccountTypeChildList,
        id: uuidv4(),
      };
      setTargetAccountChildTableList([...targetAccountChildTableList, obj]);
      const removeFromList =
        targetAccountTypes &&
        targetAccountTypes.length > 0 &&
        targetAccountTypes.filter((ele) => ele.id !== targetAccountType.id);
      setTargetAccountTypes(removeFromList);
      setTargetAccountType(null);
      setTargetAccountTypeChildList(null);
    }
  };
  const deleteTargetAccountChildHandle = (id) => {
    if (id) {
      const findDeletedData = accountTypesState?.data?.find(
        (ele) => ele.id === id?.type?.id
      );
      setTargetAccountTypes([...accountTypes, findDeletedData]);
      const findDeletedDataList = targetAccountChildTableList?.filter(
        (ele) => ele.id !== id?.id
      );
      setTargetAccountChildTableList(findDeletedDataList);
    }
  };

  // need to create two state sourceAccountType and targetAccountType. monday done


  return (
    <>
      <Paper className={classes.paper} elevation={0} style={{ width: "100%" }}>
        <Container style={{ maxWidth: "100%" }}>
          <Grid container style={{ margin: "0", alignItems: "center" }}>
            <Header
              gridSize="12"
              source={customFieldIcon}
              headerText="Add Voucher Template"
            />
          </Grid>

          {/* ============Voucher Temporary Fields============ */}
          <Grid
            container
            marginTop={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={3}>
              <TextField
                label="Voucher"
                style={{ width: "300px" }}
                variant="outlined"
                size="small"
                margin="dense"
                value={vendorName || ""}
                onChange={(e) => setVendorName(e.target.value)}
                error={customErrors.vendorName}
                onBlur={() => {
                  setVendorName(vendorName?.replace(/\s+/g, " ").trim());
                  vendorName
                    ? setCustomErrors({ ...customErrors, vendorName: false })
                    : setCustomErrors({ ...customErrors, vendorName: true });
                }}
                onInput={(e) =>
                  (e.target.value = e.target.value.toString().slice(0, 20))
                }
              />
            </Grid>

            <Grid item xs={3}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Autocomplete
                  // options={(accGrps && accGrps.length > 0 && accGrps.filter(option => (
                  //     !debitAccounts.some(selectedOption => selectedOption.id === option.id)
                  // ))) || []}

                  options={accountGroups || []}
                  value={null}
                  size="small"
                  style={{ width: "300px" }}
                  // getOptionLabel={(option) => `${option?.name}` || ""}
                  getOptionLabel={(option) =>
                    `${option?.name}(${option?.siteName})` || ""
                  }
                  getOptionSelected={(option, value) =>
                    option?.name === value?.name
                  }
                  onChange={(event, newValue) => {
                    handleAccountChange(event, newValue, "debit");
                  }}
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Debit Group"
                      size="small"
                      variant="outlined"
                      error={customErrors.debitAccounts}
                      onBlur={() => {
                        debitAccounts && debitAccounts.length > 0
                          ? setCustomErrors({
                              ...customErrors,
                              debitAccounts: false,
                            })
                          : setCustomErrors({
                              ...customErrors,
                              debitAccounts: true,
                            });
                      }}
                      onChange={debounceData}
                      onKeyPress={(e) => handleKeyPress(e, "debit")}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {debitAccounts &&
                  debitAccounts.length > 0 &&
                  debitAccounts.map((val, index) => (
                    <div
                      style={{ display: "inline-flex", alignItems: "center" }}
                    >
                      <Button
                        disabled
                        size="small"
                        className="account_group_btn"
                        sx={{
                          marginLeft: 1,
                          marginY: "5px",
                          background: "green",
                          color: "#fff !important",
                        }}
                      >
                        {val.name}
                        <span>{val?.siteName && `(${val?.siteName})`}</span>
                      </Button>
                      <ClearIcon
                        sx={{ fontSize: 16, color: "red", cursor: "pointer" }}
                        onClick={() => {
                          const accounts = [...debitAccounts];
                          accounts.splice(index, 1);
                          setDebitAccounts(accounts);
                        }}
                      />
                    </div>
                  ))}
              </div>
            </Grid>

            <Grid item xs={3}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Autocomplete
                  // options={(accountGroups && accountGroups.length > 0 && accountGroups.filter(val => val.name !== "All")) || []}
                  // options={(accGrps && accGrps.length > 0 && accGrps.filter(option => (
                  //     !creditAccounts.some(selectedOption => selectedOption.id === option.id)
                  // ))) || []}
                  options={accountGroups || []}
                  value={null}
                  size="small"
                  style={{ width: "300px" }}
                  getOptionLabel={(option) =>
                    `${option?.name}(${option?.siteName})` || ""
                  }
                  // getOptionLabel={(option) => `${option?.name}` || ""}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  // onChange={(event, newValue) => setCreditAccounts(newValue)}
                  onChange={(event, newValue) => {
                    handleAccountChange(event, newValue, "credit");
                  }}
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Credit Groups"
                      size="small"
                      variant="outlined"
                      error={customErrors.creditAccounts}
                      helperText={customErrors.creditAccounts}
                      onBlur={() => {
                        creditAccounts && creditAccounts.length > 0
                          ? setCustomErrors({
                              ...customErrors,
                              creditAccounts: false,
                            })
                          : setCustomErrors({
                              ...customErrors,
                              creditAccounts: true,
                            });
                      }}
                      onChange={debounceData}
                      onKeyPress={(e) => handleKeyPress(e, "credit")}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {creditAccounts &&
                  creditAccounts.length > 0 &&
                  creditAccounts.map((val, index) => (
                    <div
                      style={{ display: "inline-flex", alignItems: "center" }}
                    >
                      <Button
                        disabled
                        size="small"
                        className="account_group_btn"
                        sx={{
                          marginLeft: 1,
                          marginY: "5px",
                          background: "#a11919",
                          color: "#fff !important",
                        }}
                      >
                        {val.name}
                        <span>{val?.siteName && `(${val?.siteName})`}</span>
                      </Button>
                      <ClearIcon
                        sx={{ fontSize: 16, color: "red", cursor: "pointer" }}
                        onClick={() => {
                          const accounts = [...creditAccounts];
                          accounts.splice(index, 1);
                          setCreditAccounts(accounts);
                        }}
                      />
                    </div>
                  ))}
              </div>
            </Grid>

            <Grid item xs={4} marginTop={2}>
              <Autocomplete
                options={stateActionList || []}
                value={nextState}
                size="small"
                fullWidth
                getOptionLabel={(option) => `${option?.name}` || ""}
                getOptionSelected={(option, value) =>
                  option?.name === value?.name
                }
                onChange={(event, newValue) => {
                  newValue && setNextState(newValue);
                }}
                style={{ width: "300px" }}
                variant="outlined"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Current Status"
                    size="small"
                    required
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>

          {/* ==============Child Account Configuration Collapsable Data=============== */}
          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <ChildAccountConfiguration
              addTargetAccountChildHandle={addTargetAccountChildHandle}
              deleteTargetAccountChildHandle={deleteTargetAccountChildHandle}
              sourceAccountChildTableList={sourceAccountChildTableList}
              deleteSourceAccountChildHandle={deleteSourceAccountChildHandle}
              setSourceAccountChildTableList={setSourceAccountChildTableList}
              addSourceAccountChildHandle={addSourceAccountChildHandle}
              childConfigError={childConfigError}
              setChildConfigError={setChildConfigError}
              accountTypes={accountTypes || []}
              targetAccountTypes={targetAccountTypes || []}
              sourceAccountType={sourceAccountType}
              setSourceAccountType={setSourceAccountType}
              sourceAccountTypeChildList={sourceAccountTypeChildList}
              setSourceAccountTypeChildList={setSourceAccountTypeChildList}
              sourceAccountTypeChildLists={sourceAccountTypeChildLists}
              setSourceAccountTypeChildLists={setSourceAccountTypeChildLists}
              targetAccountType={targetAccountType}
              setTargetAccountType={setTargetAccountType}
              setAccountTypeChildLists={setAccountTypeChildLists}
              accountTypeChildLists={accountTypeChildLists}
              targetAccountTypeChildLists={targetAccountTypeChildLists}
              targetAccountTypeChildList={targetAccountTypeChildList}
              setTargetAccountTypeChildLists={setTargetAccountTypeChildLists}
              setTargetAccountTypeChildList={setTargetAccountTypeChildList}
              targetAccountChildTableList={targetAccountChildTableList}
              setTargetAccountChildTableList={setTargetAccountChildTableList}
            />
          </Grid>

          {/* ==============Collapsable Data=============== */}
          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <DetailsViewConfigurations
              templateViewList={templateViewList}
              selectTemplateView={selectTemplateView}
              setSelectTemplateView={setSelectTemplateView}
              customErrors={customErrors}
              setCustomErrors={setCustomErrors}
            />
          </Grid>
          {/* ==============Collapsable Data=============== */}
          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <DueDataConfigurations
              overDueBoolean={overDueBoolean}
              setOverDueBoolean={setOverDueBoolean}
              accountCategory={accountCategory}
              setAccountCategory={setAccountCategory}
              overDueDays={overDueDays}
              setOverDueDays={setOverDueDays}
              customErrors={customErrors}
              setCustomErrors={setCustomErrors}
              checkboxError={checkboxError}
              setCheckboxError={setCheckboxError}
              radioError={radioError}
              setRadioError={setRadioError}
              taggedInvoice={taggedInvoice}
            />
          </Grid>
          {/* ==============Collapsable Data=============== */}
          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <TaggedFunction
              overDueBoolean={overDueBoolean}
              taggedInvoice={taggedInvoice}
              setTaggedInvoice={setTaggedInvoice}
              customErrors={customErrors}
              setCustomErrors={setCustomErrors}
            />
          </Grid>

          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <MandatoryCheck
              invoiceOrNoteNumber={invoiceOrNoteNumber}
              setInvoiceorNotNumber={setInvoiceorNotNumber}
              accountCode={accountCode}
              setAccountCode={setAccountCode}
              transactionType={transactionType}
              setTransactionType={setTransactionType}
              customErrors={customErrors}
              setCustomErrors={setCustomErrors}
            />
          </Grid>
          <Grid
            container
            marginTop={4}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
            fullWidth
          >
            <ConfigurationCheckFields
              openModal={openModal}
              setOpenModal={setOpenModal}
              dynamicFields={dynamicFields}
              setDynamicFields={setDynamicFields}
              setHandleTableComponent={setHandleTableComponent}
              openEditorFuntion={openEditorFuntion}
            />
          </Grid>

          {/* ============Voucher Dynamic Fields============ */}
          {/* <Grid
            container
            marginTop={2}
            marginBottom={2}
            sx={{ backgroundColor: "white", width: "100%" }}
          >
            <Card style={{ width: "100%" }}>
              <CardContent>
                <Grid item xs={12} marginY={3}>
                  <Typography variant="h6">
                    {"Configure Voucher Fields"}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <DragAndDropComponent
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    dynamicFields={dynamicFields}
                    setDynamicFields={setDynamicFields}
                    setHandleTableComponent={setHandleTableComponent}
                    openEditorFuntion={openEditorFuntion}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid> */}

          {/* ================Buttons============ */}

          <Grid
            container
            justifyContent="flex-end"
            style={{ margin: "20px 0px", padding: "10px 0px" }}
          >
            <Grid item>
              <Button
                onClick={handlesaveTemplate}
                color="primary"
                style={{
                  background: `${themecolor}`,
                  display: checkPermissionExist(endPoints, voucherTypeSaveUrl)
                    ? "inline-flex"
                    : "none",
                }}
                variant="contained"
              >
                Save Template{" "}
              </Button>
            </Grid>

            <Grid item marginLeft={2}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Cancel{" "}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>

      {/* ==============Alerts=============== */}
      <AlertBar
        visible={alerts?.visible}
        closeAlertBar={closeAlertBar}
        alertMessage={alerts?.alertMessage}
        alertType={alerts?.alertType}
      />

      {/* =============Loader============= */}
      {loading && <LoadingComponent />}

      {/* =========Table Component Modal=========== */}
      {openModal && (
        <TableComponentModal
          open={openModal}
          closeDialogBox={closeDialogBox}
          tableItem={tableItem}
          dynamicFields={dynamicFields}
          setDynamicFields={setDynamicFields}
          errorAlert={errorAlert}
        />
      )}

      {/* ======================Editor Modal============ */}
      {openEditor && (
        <Editor
          open={openEditor}
          closeDialogBox={closeEditor}
          dynamicFields={dynamicFields}
          setDynamicFields={setDynamicFields}
          editedIndex={editedIndex}
          selectedField={selectedField}
          setSelectedField={setSelectedField}
          errorAlert={errorAlert}
        />
      )}
    </>
  );
};

export default withStyles(styles)(CreateVoucherTemp);
// setNetCreditTotalAmount(netCreditTotalAmount)
// setNetDebitTotalAmount(netDebitTotalAmount)
