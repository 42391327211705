import * as React from "react";
// import { headers } from "./util";
import { themecolor, cellColor } from "../Navigation";
import { useNavigate } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { DataStatus } from "../CommonComps/DataStatus";
import { Editedtext } from "../CommonComps/TextTemplate";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { getEncryptPayload } from "../Network_Utility";
import { userService } from "../userService";
import {
  reportChildListUrl,
  transactionsUrl,
  trialBalanceUrl,
} from "../config";
import AmountInfo from "../CommonComps/AmountInfo";
import { updateNestedItem } from "../utils";
import ColorInfo from "../CommonComps/ColorInfo";
import { AutoSizer, List } from "react-virtualized";
export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: `${themecolor} !important`,
    padding: "8px!important",
    color: "white !important",
    fontSize: "14px !important",
    width: "fit-content !important",
  },
  body: {
    fontSize: 13,
    fontWeight: 400,
    padding: "0px 0px!important",
    color: "#555",
    borderBottom: "none",
    width: "20% !important",
  },
  p: {
    padding: "0px 10px !important",
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
  },
}))(TableRow);

function Row({
  row,
  index,
  tableData,
  setData,
  setAlerts,
  alerts,
  errorAlert,
  successAlert,
  level = 0,
  site,
  fromDate,
  toDate,
  month,
  navigateToLedger,
  setLoading,
  collapse,
  searchField,
  setSearchField,
  setOpenDefault,
}) {
  const { childList } = row;
  const [rowData, setRowData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [isAccount, setIsAccount] = React.useState(false);

  const navigate = useNavigate();

  const handleEditCategory = (row) => {
    navigate("/addAccountGroup", {
      state: row?.id,
    });
  };

  const shadeColor = (color, percent) => {
    const num = parseInt(color.slice(1), 16);
    const R = num >> 16;
    const G = (num >> 8) & 0x00ff;
    const B = num & 0x0000ff;

    const newR = Math.min(255, R + percent);
    const newG = Math.min(255, G + percent);
    const newB = Math.min(255, B + percent);

    return `#${((1 << 24) | (newR << 16) | (newG << 8) | newB)
      .toString(16)
      .slice(1)}`;
  };

  const textColorForBackground = (backgroundColor) => {
    // Calculate the brightness of the background color
    const num = parseInt(backgroundColor.slice(1), 16);
    const brightness =
      0.299 * ((num >> 16) & 0xff) +
      0.587 * ((num >> 8) & 0xff) +
      0.114 * (num & 0xff);

    // Determine the text color based on brightness
    return brightness > 128 ? "#000000" : "#ffffff"; // Black text on light backgrounds, white text on dark backgrounds
  };

  const maxColorChange = 50;

  // const handleClickIcon = () => setOpen(!open);

  const handleTransaction = async (row) => {
    let payload = {
      creditAccountId: null,
      debitAccountId: null,
      accounTypeIds: [],
      accountIds: [],
      groupIds: [row.id],
      fromDate: null,
      toDate: null,
      pageNo: null,
      pageSize: null,
      siteId: site ? site.id : parseInt(localStorage.getItem("siteId")),
      fromAmount: null,
      toAmount: null,
      transactionType: null,
      voucherTemplateTypeId: null,
    };
    const encryptedPayload = getEncryptPayload(payload, true);
    try {
      setLoading(true);
      const res = await userService.postData(transactionsUrl, encryptedPayload);
      if (res && res.data && res.data.data && res.data.status === 200) {
        const { data, totalLength } = res.data.data;
        if (data && Array.isArray(data) && data.length > 0) {
          successAlert("Transactions is Available!");

          navigate("/transactionTable", {
            state: data ? data : [],
          });
        } else {
          errorAlert("No Transcations is Available!");
        }
      } else errorAlert(res?.data?.message);
    } catch (error) {
      errorAlert("No Transcations is Available!");
    } finally {
      setLoading(false);
    }
  };

  const isLeafNode = !childList || childList.length == 0;

  const handleClickIcon = (row) => {
    if (!open) {
      if (isLeafNode) {
        makeAPICallOnLeafNode(row);
        // const permissionExist = checkPermissionExist(endPoints, trialBalanceUrl)
        // if (permissionExist)

        // else errorAlert(`Unautharized to access accounts trial balance of ${row.name} group.`)
      } else setOpen(!open);
      // Set open to true before making the API call
    } else setOpen(!open); // Toggle open state
    level === 0 && setOpenDefault({ level: level, open: !open });
  };

  const makeAPICallOnLeafNode = async (row) => {
    const payload = {
      reportId: 5,
      groupId: row?.id,
      fromDate,
      monthId: month?.monthValue,
      toDate,
      siteId: localStorage.getItem("siteId"),
    };
    const encryptedPayload = getEncryptPayload(payload, true);

    const apiUrl = reportChildListUrl;

    try {
      setLoading(true);
      const response = await userService.postData(apiUrl, encryptedPayload);

      if (response && response.data && response.data.status == 200) {
        if (
          response?.data?.data &&
          response?.data?.data.length > 0 &&
          response?.data?.data[0]?.childList?.length > 0
        ) {
          const updatedRow = {
            ...row,
            childList: response?.data?.data[0]?.childList,
          };
          setRowData(updatedRow);

          const updatedData = updateNestedItem(tableData, updatedRow);
          setData(updatedData);

          setOpen(true);
        } else getAccountsList(row);
      } else {
        // Handle API error
        errorAlert(response?.data?.message);
        console.error("API Error:", response.data.message);
      }
    } catch (error) {
      // Handle API call error
      console.error("API Call Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const getAccountsList = async (row) => {
    const payload = {
      fromDate,
      monthId: month?.id,
      toDate,
      siteId: parseInt(localStorage.getItem("siteId")),
      accountGroupIds: [row?.id],
      tokenData: {
        companyId: "11",
        moduleId: null,
        organizationId: null,
        userCode: null,
        userId: "14000",
        userName: null,
        userRoleId: null,
      },
      financialYearId: 5,
    };

    const apiUrl = trialBalanceUrl;

    try {
      setLoading(true);
      const response = await userService.postData(apiUrl, payload);

      if (response && response.data && response.data.status === 200) {
        const { accountInfoList } = response.data.data;

        if (accountInfoList && accountInfoList.length > 0) {
          setOpen(true);
          setIsAccount(true);
          const newList = accountInfoList.map((item) => ({
            ...item,
            accountId: item?.accountId,
            name: item.accountName,

            isAccount: true,
            childList: [], // Make sure to set childList as an empty array
          }));

          const updatedRow = { ...row, childList: newList };
          setRowData(updatedRow);

          // Update the main data array with the updated row
          const updatedData = updateNestedItem(tableData, updatedRow);
          setData(updatedData);

          // Toggle open state
        } else {
          // If there are no child items, just toggle open state
          // setOpen(!open);
          successAlert(`No Account available for ${row.name}`);
        }
      } else {
        // Handle API error
        errorAlert(response?.data?.message);
        console.error("API Error:", response.data.message);
      }
    } catch (error) {
      // Handle API call error
      console.error("API Call Error:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    setRowData(row);
  }, [row]);
  return (
    <React.Fragment>
      <StyledTableRow>
        {rowData && !rowData?.isAccount ? (
          <StyledTableCell
            style={{
              backgroundColor: searchField
                ? "#fff"
                : shadeColor(cellColor, level * 40),
              color: textColorForBackground(
                shadeColor(cellColor, level * 10, maxColorChange)
              ),
              padding: 0,
              minWidth: "100px",
            }}
          >
            <Typography variant="body2" className="table_value">
              {index}
              <IconButton
                aria-label="expand rowData"
                size="small"
                style={{
                  color: textColorForBackground(
                    shadeColor(cellColor, level * 10, maxColorChange)
                  ),
                }}
                onClick={() => handleClickIcon(rowData)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Typography>
          </StyledTableCell>
        ) : (
          <StyledTableCell
            style={{
              backgroundColor: searchField
                ? "#fff"
                : shadeColor(cellColor, level * 40),
              color: textColorForBackground(
                shadeColor(cellColor, level * 10, maxColorChange)
              ),

              padding: 0,
              minWidth: "100px",
            }}
          >
            <Typography variant="body2" className="table_value">
              {index}
              {!searchField ? (
                <IconButton disabled>
                  <KeyboardArrowUpIcon style={{ visibility: "hidden" }} />
                </IconButton>
              ) : (
                <span></span>
              )}
            </Typography>
          </StyledTableCell>
        )}

        <StyledTableCell
          style={{
            backgroundColor: searchField
              ? "#fff"
              : shadeColor(cellColor, level * 40),
            color: textColorForBackground(
              shadeColor(cellColor, level * 10, maxColorChange)
            ),
            padding: 0,
            width: "20% !important",
          }}
        >
          <Typography
            variant="body2"
            className="table_value"
            sx={{ cursor: "pointer", color: rowData?.isAccount && "blue" }}
            onClick={
              collapse === "openChildGeneralLedge" || rowData?.isAccount
                ? () => navigateToLedger(rowData)
                : null
            }
          >
            <Editedtext num="50" text={rowData?.name} />
          </Typography>
        </StyledTableCell>

        <StyledTableCell
          align="start"
          style={{
            backgroundColor: searchField
              ? "#fff"
              : shadeColor(cellColor, level * 40),
            color: textColorForBackground(
              shadeColor(cellColor, level * 10, maxColorChange)
            ),
            padding: 0,
            width: "20% !important",
          }}
        >
          <Typography
            variant="body2"
            className="table_value"
            sx={{ textAlign: "end", width: "45%" }}
          >
            <span
              style={{
                color:
                  rowData?.accountGroupCurrentBalance < 0
                    ? "#0f590f"
                    : "#a50000",
              }}
            >
              {+parseInt(Math.abs(rowData?.accountGroupCurrentBalance)) ? (
                <AmountInfo amount={rowData?.accountGroupCurrentBalance} />
              ) : (
                "-"
              )}
              &nbsp;{" "}
              {rowData?.accountGroupCurrentBalance &&
              rowData?.accountGroupCurrentBalance < 0
                ? "Dr."
                : rowData?.accountGroupCurrentBalance &&
                  rowData?.accountGroupCurrentBalance >= 0
                ? "Cr."
                : ""}
            </span>
          </Typography>
        </StyledTableCell>

        <StyledTableCell
          style={{
            backgroundColor: searchField
              ? "#fff"
              : shadeColor(cellColor, level * 40),
            color: textColorForBackground(
              shadeColor(cellColor, level * 10, maxColorChange)
            ),
            padding: 0,
            width: "20% !important",
          }}
        >
          <Typography variant="body2" className="table_value">
            <Editedtext num="80" text={rowData?.description} />
          </Typography>
        </StyledTableCell>
        <StyledTableCell
          style={{
            backgroundColor: searchField
              ? "#fff"
              : shadeColor(cellColor, level * 40),
            color: textColorForBackground(
              shadeColor(cellColor, level * 10, maxColorChange)
            ),
            padding: 0,
            width: "20% !important",
            display: "none",
          }}
        >
          <Typography variant="body2" className="table_value">
            <IconButton onClick={() => handleTransaction(rowData)} size="small">
              <RemoveRedEyeIcon
                style={{
                  color: textColorForBackground(
                    shadeColor(cellColor, level * 10, maxColorChange)
                  ),
                }}
              />
            </IconButton>
          </Typography>
          {/* <Typography variant="body2" className="table_value">
            <RemoveRedEyeIcon
              style={{
                color: textColorForBackground(
                  shadeColor(cellColor, level * 10, maxColorChange)
                )
              }}
              onClick={() => handleTransaction(rowData)}
            />
          </Typography> */}
        </StyledTableCell>
      </StyledTableRow>

      <StyledTableRow>
        <StyledTableCell sx={{ borderBottom: "none" }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <TableContainer component={Paper}>
                <Table aria-label="purchases">
                  <TableBody>
                    {isAccount && rowData ? (
                      <AutoSizer style={{ height: "400px" }}>
                        {({ height, width }) => {
                          const itemsPerRow = 1;

                          return (
                            rowData.childList &&
                            rowData.childList.length > 0 && (
                              <List
                                width={width}
                                height={500}
                                rowHeight={50}
                                rowCount={Math.ceil(
                                  rowData.childList?.length / itemsPerRow
                                )}
                                overscanRowCount={3}
                                rowRenderer={({ index, key, style }) => {
                                  const startIndex = index * itemsPerRow;
                                  const endIndex = Math.min(
                                    startIndex + itemsPerRow,
                                    rowData.childList?.length
                                  );
                                  const items = rowData.childList
                                    ?.slice(startIndex, endIndex)
                                    .map((val, ind) => (
                                      <>
                                        <StyledTableCell
                                          style={{
                                            backgroundColor: searchField
                                              ? "#fff"
                                              : shadeColor(
                                                  cellColor,
                                                  level * 40
                                                ),
                                            color: textColorForBackground(
                                              shadeColor(
                                                cellColor,
                                                level * 10,
                                                maxColorChange
                                              )
                                            ),
                                            padding: 0,
                                            minWidth: "150px",
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            className="table_value"
                                          >
                                            {" "}
                                            {index + 1}
                                            <IconButton disabled>
                                              <KeyboardArrowUpIcon
                                                style={{ visibility: "hidden" }}
                                              />
                                            </IconButton>
                                          </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          style={{
                                            backgroundColor: searchField
                                              ? "#fff"
                                              : shadeColor(
                                                  cellColor,
                                                  level * 40
                                                ),
                                            color: textColorForBackground(
                                              shadeColor(
                                                cellColor,
                                                level * 10,
                                                maxColorChange
                                              )
                                            ),
                                            padding: 0,
                                            minWidth: "350px",
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            className="table_value"
                                            sx={{
                                              cursor: "pointer",
                                              color: val?.isAccount && "blue",
                                            }}
                                            onClick={
                                              collapse ===
                                                "openChildGeneralLedge" ||
                                              val?.isAccount
                                                ? () => navigateToLedger(val)
                                                : null
                                            }
                                          >
                                            <Editedtext
                                              num="50"
                                              text={val?.name}
                                            />
                                          </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          align="start"
                                          style={{
                                            backgroundColor: searchField
                                              ? "#fff"
                                              : shadeColor(
                                                  cellColor,
                                                  level * 40
                                                ),
                                            color: textColorForBackground(
                                              shadeColor(
                                                cellColor,
                                                level * 10,
                                                maxColorChange
                                              )
                                            ),
                                            padding: 0,
                                            width: "20% !important",
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            className="table_value"
                                            sx={{
                                              textAlign: "end",
                                              width: "45%",
                                            }}
                                          >
                                            <span
                                              style={{
                                                color:
                                                  val?.accountGroupCurrentBalance <
                                                  0
                                                    ? "#0f590f"
                                                    : "#a50000",
                                              }}
                                            >
                                              {+parseInt(
                                                Math.abs(
                                                  val?.accountGroupCurrentBalance
                                                )
                                              ) ? (
                                                <AmountInfo
                                                  amount={
                                                    val?.accountGroupCurrentBalance
                                                  }
                                                />
                                              ) : (
                                                "-"
                                              )}
                                              &nbsp;{" "}
                                              {val?.accountGroupCurrentBalance &&
                                              val?.accountGroupCurrentBalance <
                                                0
                                                ? "Dr."
                                                : val?.accountGroupCurrentBalance &&
                                                  val?.accountGroupCurrentBalance >=
                                                    0
                                                ? "Cr."
                                                : ""}
                                            </span>
                                          </Typography>
                                        </StyledTableCell>

                                        <StyledTableCell
                                          style={{
                                            backgroundColor: searchField
                                              ? "#fff"
                                              : shadeColor(
                                                  cellColor,
                                                  level * 40
                                                ),
                                            color: textColorForBackground(
                                              shadeColor(
                                                cellColor,
                                                level * 10,
                                                maxColorChange
                                              )
                                            ),
                                            padding: 0,
                                            width: "20% !important",
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            className="table_value"
                                          >
                                            <Editedtext
                                              num="80"
                                              text={val?.description}
                                            />
                                          </Typography>
                                        </StyledTableCell>

                                        <StyledTableCell
                                          style={{
                                            backgroundColor: searchField
                                              ? "#fff"
                                              : shadeColor(
                                                  cellColor,
                                                  level * 40
                                                ),
                                            color: textColorForBackground(
                                              shadeColor(
                                                cellColor,
                                                level * 10,
                                                maxColorChange
                                              )
                                            ),
                                            padding: 0,
                                            width: "20% !important",
                                            display: "none",
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            className="table_value"
                                          >
                                            <IconButton
                                              onClick={() =>
                                                handleTransaction(val)
                                              }
                                              size="small"
                                            >
                                              <RemoveRedEyeIcon
                                                style={{
                                                  color: textColorForBackground(
                                                    shadeColor(
                                                      cellColor,
                                                      level * 10,
                                                      maxColorChange
                                                    )
                                                  ),
                                                }}
                                              />
                                            </IconButton>
                                          </Typography>
                                        </StyledTableCell>
                                      </>
                                    ));

                                  return (
                                    <StyledTableRow key={key} style={style}>
                                      {items}
                                    </StyledTableRow>
                                  );
                                }}
                              />
                            )
                          );
                        }}
                      </AutoSizer>
                    ) : (
                      rowData &&
                      rowData.childList &&
                      rowData.childList.length > 0 &&
                      rowData.childList.map((val, ind) => (
                        <Row
                          key={val?.name}
                          row={val}
                          index={`${index}.${ind + 1}`}
                          tableData={tableData}
                          setData={setData}
                          level={level + 1}
                          errorAlert={errorAlert}
                          successAlert={successAlert}
                          site={site}
                          fromDate={fromDate}
                          toDate={toDate}
                          month={month}
                          navigateToLedger={navigateToLedger}
                          setLoading={setLoading}
                          // collapse={"openChildGeneralLedge"}
                          searchField={searchField}
                          setSearchField={setSearchField}
                        />
                      ))
                    )}
                    {/* {rowData &&
                      rowData.childList &&
                      rowData.childList.length > 0 &&
                      rowData.childList.map((val, ind) => (
                        <Row
                          key={val?.name}
                          row={val}
                          index={`${index}.${ind + 1}`}
                          tableData={tableData}
                          setData={setData}
                          level={level + 1}
                          errorAlert={errorAlert}
                          successAlert={successAlert}
                          site={site}
                          fromDate={fromDate}
                          toDate={toDate}
                          month={month}
                          navigateToLedger={navigateToLedger}
                          setLoading={setLoading}
                          // collapse={"openChildGeneralLedge"}
                          searchField={searchField}
                          setSearchField={setSearchField}
                        />
                      ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function ReportsTable({
  data,
  setData,
  pageNo,
  setPageNo,
  totalPages,
  setTotalPages,
  pageSize,
  setPageSize,
  setAlerts,
  alerts,
  successAlert,
  errorAlert,
  site,
  fromDate,
  toDate,
  month,
  navigateToLedger,
  setLoading,
  searchField,
  setSearchField,
  mainData,
  setOpenDefault,
}) {
  const onPageChange = (event, value) => setPageNo(value);
  const headers = [
    { id: 1, name: "S.No." },
    { id: 2, name: "Name" },
    { id: 3, name: "Account Group Balance" },
    { id: 4, name: "Description" },
    // { id: 5, name: "Transactions" }
  ];

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ marginTop: 20, height: data && data.length > 0 && "calc(100vh - 235px)" }}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <StyledTableRow>
              {headers &&
                Array.isArray(headers) &&
                headers.length > 0 &&
                headers.map((val) => (
                  <StyledTableCell
                    key={val.id}
                    width={val?.name === "Account Group Balance" ? 50 : null}
                  >
                    {val.name}
                  </StyledTableCell>
                ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map((row, index) => {
                return (
                  <Row
                    key={row?.id}
                    row={row}
                    index={(pageNo - 1) * pageSize + index + 1}
                    tableData={mainData}
                    setData={setData}
                    setAlerts={setAlerts}
                    alerts={alerts}
                    errorAlert={errorAlert}
                    successAlert={successAlert}
                    site={site}
                    fromDate={fromDate}
                    toDate={toDate}
                    month={month}
                    navigateToLedger={navigateToLedger}
                    setLoading={setLoading}
                    searchField={searchField}
                    setSearchField={setSearchField}
                    setOpenDefault={setOpenDefault}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {data && data.length > 0 && <ColorInfo />}
      {data && data.length < 1 && <DataStatus />}
    </>
  );
}
