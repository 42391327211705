import { siteUrl, headOfficeSiteUrl } from "../../config";
import {
  dayBookTableHeaders,
  generalLedgerTableHeader,
  trialBalanceSiteWiseTableHeader,
} from "../../config/ColumnJson";
import { userService } from "../../userService";
import {
  fetchFinancialYearMonth,
  fetchFinancialYears,
} from "./FinancialYearsAction";
import { setNewSite } from "./siteAction";

const DATA_FETCH_SUCCESS = "DATA_FETCH_SUCCESS";
const DATA_FETCH_FAILURE = "DATA_FETCH_FAILURE";

const PAGE_URL = ["/daybook", "/trialBalance", "/fixedAssets", "/bankBook", "/liabilities", "/profitLoss", "/balanceSheet", "/cashflow", '/generalLedger'];

const getHeadOffice = (
  data,
  setIsLoading,
  dispatch,
  errorAlert,
  primarySiteId,
  navigate,
  permissionList
) => {
  userService
    .get(headOfficeSiteUrl)
    .then((res) => {
      if (res && res.data && res.data) {
        let sites = data;
        sites.forEach((site) => {
          site.isHeadOffice = site.id === res.data.data;
        });
        const filteredSIte =
          sites.find(
            (val) => val?.name === "HEAD OFFICE" || val?.name === "Head Office"
          ) || sites[0];
        localStorage.setItem("siteId", primarySiteId || filteredSIte.id);
        dispatch(siteRequestSucess(sites));
        dispatch(setNewSite(primarySiteId || filteredSIte));

        setTimeout(() => {
          dispatch(
            fetchFinancialYears(
              setIsLoading,
              errorAlert,
              navigate,
              permissionList
            )
          );
          dispatch(fetchFinancialYearMonth(setIsLoading, errorAlert));
        }, 100);
      } else {
        return false;
      }
    })
    .catch((err) => {
      errorAlert("Something went wrong");
    });
};

export function fetchSiteList(
  setIsLoading,
  errorAlert,
  primarySiteId,
  navigate,
  permissionList
) {
  return (dispatch) => {
    setIsLoading(true);

    userService
      .getSites(siteUrl)
      .then((res) => {
        if (res && res.data && res.data.data) {
          let sites = res.data.data;
          const sitesArray = sites?.map((ele) => ({ ...ele, isShow: true }));
          const finalSiteName = [
            { id: 909, name: "Select All", alias: "all", isShow: false },
            { id: 910, name: "Remove All", alias: "remove", isShow: false },
            ...sitesArray,
          ];

          const columnConfig = [
            {
              id: 1,
              page: PAGE_URL[0],
              dayBookTable: dayBookTableHeaders,
            },
            {
              id: 2,
              page: PAGE_URL[1],
              siteWiseTable: trialBalanceSiteWiseTableHeader,
              siteName: finalSiteName,
            },
            {
              id: 3,
              page: PAGE_URL[2],
              siteName: finalSiteName,
            },
            {
              id: 4,
              page: PAGE_URL[3],
              siteName: finalSiteName,
            },
            {
              id: 5,
              page: PAGE_URL[4],
              siteName: finalSiteName,
            },
            {
              id: 6,
              page: PAGE_URL[5],
              siteName: finalSiteName,
            },
            {
              id: 7,
              page: PAGE_URL[6],
              siteName: finalSiteName,
            },
            {
              id: 8,
              page: PAGE_URL[7],
              siteName: finalSiteName,
            },
            {
              id: 9,
              page: PAGE_URL[8],
              generalLedger: generalLedgerTableHeader,
            },
          ];
          localStorage.setItem("columnConfig", JSON.stringify(columnConfig));

          getHeadOffice(
            sites,
            setIsLoading,
            dispatch,
            errorAlert,
            primarySiteId,
            navigate,
            permissionList
          );
        } else {
          dispatch(siteRequestFailure(res.data));
          errorAlert(res?.data?.message || "Something went wrong");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        errorAlert("Something went wrong");
        setIsLoading(false);
      });
  };
}

export const siteRequestFailure = (data) => {
  return {
    type: DATA_FETCH_FAILURE,
    data: data,
  };
};
export const siteRequestSucess = (data) => {
  return {
    type: DATA_FETCH_SUCCESS,
    data: data,
  };
};
