import React from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

export var themecolor;

const Footer = () => {
  const reduxState = useSelector((state) => state?.rootReducer);
  let companyDto = reduxState?.companyReducer?.data || null;
  themecolor = companyDto?.colourTheme[0]?.colourCode || "#3f79b5";

  const useStyled = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: themecolor ? themecolor : "rgb(63, 121, 181) !important",
    bottom: 0,
    width: "100%",
    height: "25px",
    position: "fixed",
    color: "white",
    zIndex: "2000"
  };

  return (
    <div style={useStyled}>
      <Typography
        variant="h6"
        component="h2"
        sx={{ fontWeight: "lighter", fontSize: "11px !important" }}
      >
        CopyRight @NYGGS Accounts 2023.
      </Typography>
    </div>
  );
};

export default Footer;
