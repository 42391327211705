import config from "../../config";
import { userService } from "../../userService";

export const AssetsAction = {
  getAssetsList,
  getBankBookList
};

async function getAssetsList(payload) {
  try {
    const apiEndPoint = `${config.baseUrl}${config.accountTrialBalanceUrl}`;
    const response = await userService.postData(apiEndPoint, payload);
    // alert(response)
    if (response) {
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
}

async function getBankBookList(payload) {
  try {
    const apiEndPoint = `${config.baseUrl}${config.bankBookReportUrl}`;
    const response = await userService.postData(apiEndPoint, payload);
    // alert(response)
    if (response) {
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
}
