import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { themecolor } from "../Navigation";
import { TableCell, TableRow } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { AiFillAccountBook, AiFillPayCircle } from "react-icons/ai";
// import { TbSwitchHorizontal } from "react-icons/tb";
import { BiCustomize } from "react-icons/bi";
import { AiOutlineTransaction } from "react-icons/ai";
import { GiBlackBook } from "react-icons/gi";
import { SiSoundcharts } from "react-icons/si";
import { HiTemplate } from "react-icons/hi";
import PostAddIcon from "@mui/icons-material/PostAdd";
// import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import SettingsIcon from "@mui/icons-material/Settings";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PaidIcon from "@mui/icons-material/Paid";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
// import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
// import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { FcLineChart } from "react-icons/fc";
import { FaBalanceScale } from "react-icons/fa";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { BsCashStack } from "react-icons/bs";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { AlertBar } from "../Alert/AlertBar";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { GrTransaction } from "react-icons/gr";


export const amountLength = 15;

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: ` ${themecolor} !important`,
    padding: "8px 5px!important",
    color: "white !important",
    fontSize: "15px !important",
    width: "fit-content"
  },
  body: {
    fontSize: 13,
    fontWeight: 400,
    padding: "5px 3px!important",
    color: "#555"
    // border:"1px solid black",
    // width: 'fit-content !important',
  }
}))(TableCell);

export const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff"
    }
  }
}))(TableRow);

// keys for import
export const keys = {
  accountKey: "@cc0unt1m90r773m91@73",
  accountGroupKey: "@cc0untgR0u91m90r773m91@73",
  accountTypeKey: "@cc0unt7y931m90r773m91@73",
  transactionKey: "@cc0unt7r@ns@c7i0n1m90r773m91@73"
};

// =================Nav Menus==================//
export const navigationMenus = [
  {
    id: 1,
    name: "Dashboard",
    subName: "Dashboard",
    path: "/dashboard",
    subPath: "/dashboard",
    icon: <DashboardIcon size={20} style={{ color: "#fff" }} />
  },

  {
    id: 2,
    name: "Chart Of Accounts",
    subName: "Accounts",
    apiEndpoint: "/account/v1/get",
    path: "/account",
    subPath: "/addAccount",
    icon: <SiSoundcharts size={20} style={{ color: "#fff" }} />
  },

  {
    id: 3,
    name: "Reports",
    subName: "Reports",
    icon: <PostAddIcon style={{ color: "#fff" }} />,
    subMenus: [
      {
        id: "3.1",
        name: "Daybook",
        path: "/daybook",
        subName: "Daybook",
        subPath: "/addTransaction",
        icon: <AiOutlineTransaction size={20} style={{ color: "#fff" }} />
      },
      {
        id: "3.2",
        name: "Trial Balance",
        subName: "Trial Bal",
        apiEndpoint: "/account-transaction/v1/account/group/tree/list",
        path: "/trialBalance",
        icon: <GiBlackBook size={20} style={{ color: "#fff" }} />
      },
      {
        id: "3.3",
        name: "Bank Book",
        path: "/bankBook",
        subName: "Bank Book",
        icon: <AccountBalanceIcon size={20} style={{ color: "#fff" }} />
      },
      {
        id: "3.4",
        name: "Fixed Assets",
        subName: "Fix Asset",
        path: "/fixedAssets",
        icon: <MapsHomeWorkIcon size={20} style={{ color: "#fff" }} />
      },
      {
        id: "3.5",
        name: "Liabilities",
        subName: "Liabilities",
        path: "/liabilities",
        icon: <CurrencyRupeeIcon size={20} style={{ color: "#fff" }} />
      },
      {
        id: "3.6",
        name: "P&L Statement",
        subName: "P&L Statement",
        path: "/profitLoss",
        icon: (
          <FcLineChart size={20} style={{ color: "#fff", fontSize: "12px" }} />
        )
      },
      {
        id: "3.7",
        name: "Balance Sheet",
        subName: "Bal Sheet",
        path: "/balanceSheet",
        icon: <FaBalanceScale size={20} style={{ color: "#fff" }} />
      },
      {
        id: "3.8",
        name: "Cash Flow",
        subName: "Cash flow",
        path: "/cashflow",
        icon: <BsCashStack size={20} style={{ color: "#fff" }} />
      }
      // {
      //   id: "3.9",
      //   name: "GST Report",
      //   subName: "GST",
      //   path: "/gstReport",
      //   icon: <TbReport size={20} style={{ color: "#fff" }} />
      // },
      // {
      //   id: 1.10,
      //   name: "TDS Report",
      //   subName: "TDS",
      //   path: "/tdsReport",
      //   icon: <TbReportAnalytics size={20} style={{ color: "#fff" }} />
      // }
    ]
  },

  {
    id: 4,
    name: "Master Management",
    subName: "Master",
    icon: <SettingsIcon size={20} style={{ color: "#fff" }} />,
    subMenus: [
      {
        id: "4.1",
        name: "Account Type",
        subName: "Acc Type",
        path: "/accountType",
        subPath: "/addAccountType",
        icon: <PaidIcon size={20} style={{ color: "#fff" }} />
      },
      {
        id: "4.2",
        name: "Account Group",
        subName: "Acc Group",
        path: "/accountGroup",
        subPath: "/addAccountGroup",
        icon: <AccountBalanceIcon size={20} style={{ color: "#fff" }} />
      },
      {
        id: "4.3",
        name: "Report Group",
        subName: "Report Group",
        path: "/reportGroup",
        subPath: "/addReportGroup",
        icon: <AiFillAccountBook size={20} style={{ color: "#fff" }} />
      },
      {
        id: "4.4",
        name: "KYC Implementation",
        subName: "KYC Config",
        path: "/kycImplemenation",
        subPath: null,
        icon: <AiFillPayCircle size={20} style={{ color: "#fff" }} />
      },
      {
        id: "4.5",
        path: "/preferences",
        subPath: null,
        name: "Preferences",
        subName: "Preference",
        icon: <ConfirmationNumberIcon size={20} style={{ color: "#fff" }} />
      },
      {
        id: "4.6",
        path: "/narration",
        subPath: null,
        name: "Narration Config",
        subName: "Narration",
        icon: <ConfirmationNumberIcon size={20} style={{ color: "#fff" }} />
      },
      {
        id: "4.7",
        path: "/account-code",
        subPath: null,
        name: "Account Code",
        subName: "Acc Code",
        icon: <AdminPanelSettingsIcon size={20} style={{ color: "#fff" }} />
      },
      {
        id: "4.8",
        path: "/account-transaction-type",
        subPath: null,
        name: "Account Transaction Type",
        subName: "Transaction Type",
        icon: <GrTransaction size={20} style={{ color: "#fff" }} />
      }
    ]
  },

  {
    id: 5,
    name: "Voucher",
    subName: "Voucher",
    path: "/voucherTemplate",
    icon: <BiCustomize size={20} style={{ color: "#fff" }} />
    // subMenus: [
    //   {
    //     id: "5.1",
    //     name: "Voucher Template",
    //     path: "/voucherTemplate",
    //     subName: "Template",
    //     icon: <BiCustomize size={20} style={{ color: "#fff" }} />,
    //   },
    // ],
  },

  {
    id: 6,
    name: "Third Party Api Integration",
    subName: "API",
    // name: "Vendor Template",
    // subName: "Vendor",
    path: "/vendorTemplate",
    icon: <HiTemplate size={20} style={{ color: "#fff" }} />
    // subMenus: [
    //   {
    //     id: "6.1",
    //     name: "Template Config",
    //     path: "/vendorTemplate",
    //     subName: "Temp Config",
    //     icon: <AiFillSetting size={20} style={{ color: "#fff" }} />,
    //   },
    //   // More vendor template items...
    // ],
  },

  {
    id: 7,
    name: "Reports Configurations",
    subName: "Configurations",
    path: "/reportsMaster",
    subPath: "/reportsMaster",
    icon: <SummarizeIcon size={20} style={{ color: "#fff" }} />
  }
];

export const navRoutes = [
  {
    id: 1,
    path: "/dashboard"
  },
  {
    id: 20,
    path: "/account"
  },

  {
    id: 25,
    path: "/AccountSiteConfiguration"
  },

  {
    id: 21,
    path: "/generalLedger"
  },

  {
    id: 22,
    path: "/pendingApprovals"
  },

  {
    id: 2,
    path: "/addAccount"
  },

  {
    id: 3,
    name: "Reports",
    subMenus: [
      {
        id: "3.1",
        path: "/daybook"
      },
      {
        id: "3.10",

        path: "/addTransaction"
      },
      {
        id: "3.2",
        path: "/trialBalance"
      },
      {
        id: "3.3",
        path: "/bankBook"
      },
      {
        id: "3.4",
        path: "/fixedAssets"
      },
      {
        id: "3.5",
        path: "/liabilities"
      },
      {
        id: "3.6",
        path: "/profitLoss"
      },
      {
        id: "3.7",
        path: "/balanceSheet"
      },
      {
        id: "3.8",
        path: "/cashflow"
      }
      // {
      //   id: "3.9",
      //   name: "GST Report",
      //   subName: "GST",
      //   path: "/gstReport",
      //   icon: <TbReport size={20} style={{ color: "#fff" }} />
      // },
      // {
      //   id: 1.10,
      //   name: "TDS Report",
      //   subName: "TDS",
      //   path: "/tdsReport",
      //   icon: <TbReportAnalytics size={20} style={{ color: "#fff" }} />
      // }
    ]
  },

  {
    id: 4,
    name: "Master Management",
    subMenus: [
      {
        id: "4.1",
        path: "/accountType"
      },
      {
        id: "4.10",
        path: "/addAccountType"
      },
      {
        id: "4.11",
        path: "/updateAccountType"
      },
      {
        id: "4.2",
        path: "/accountGroup"
      },
      {
        id: "4.3",
        path: "/reportGroup"
      },
      {
        id: "4.4",
        path: "/addReportGroup"
      },
      {
        id: "4.5",
        path: "/GroupList"
      },
      {
        id: "4.20",
        path: "/addAccountGroup"
      },
      // {
      //   id: "4.3",
      //   name: "Report Group",
      //   subName: "Report Group",
      //   path: "/reportGroup",
      //   subPath: "/addReportGroup",
      //   icon: <AiFillAccountBook size={20} style={{ color: "#fff" }} />,
      // },
      {
        id: "4.4",
        path: "/kycImplemenation"
      },
      {
        id: "4.4",
        path: "/addKYCFields"
      },
      {
        id: "4.5",
        path: "/preferences"
      },
      {
        id: "4.6",
        path: "/narration"
      },
      {
        id: "4.7",
        path: "/add-narration"
      },
      {
        id: "4.8",
        path: "/account-code"
      },
      {
        id: "4.9",
        path: "/add-account-code"
      },
      {
        id: "4.0.1",
        path: "/account-transaction-type"
      },
      {
        id: "4.0.2",
        path: "/add-account-transaction-type"
      }
    ]
  },

  {
    id: 5,
    name: "Voucher",
    path: "/voucherTemplate",
    subMenus: [
      {
        id: "5.1",
        path: "/createVoucherTemp"
      },
      {
        id: "5.2",
        path: "/editTemplate"
      }
    ]
  },

  {
    id: 6,
    name: "Third Party Api Integration",
    path: "/vendorTemplate",
    // name: "Vendor Template",
    // path: "/vendorTemplate",
    subMenus: [
      {
        id: "6.1",
        path: "/mapData"
      },
      {
        id: "6.2",
        path: "/config-apis"
      }
      // More vendor template items...
    ]
  },

  {
    id: 7,
    path: "/reportsMaster"
  },
  {
    id: 20,
    name: "Bank Reconciliation",
    path: "/bankReconciliation"
  },
  {
    id: 35,
    path: "/help"
  },
];

// Function to hold api call
export function debounceFunc(cb, delay) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  };
}

// Function to block specific characters for type number
export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const checkPermissionExist = (endPoints, api) => {
  var parts = api && api.split("/");
  // var updatedApi = parts && "/" + parts.slice(1).join("/");

  var updatedApi = parts && "/" + parts.join("/");

  return endPoints?.some((val) => val.apiEndpoint === updatedApi);
  // return true;
};

export const checkPathExist = (endPoints, path) => {
  const pathExist = endPoints && endPoints.some((val) => val?.path == path);
  return pathExist;
};

export const checkModuleExist = (endPoints, moduleName) => {
  const pathExist = endPoints && endPoints.some((val) => val?.name == moduleName);
  return pathExist;
};

export const checkIsHeadOffice = (siteList, selectedSiteId) => {
  const foundSite = siteList?.find(
    (site) => site.id == selectedSiteId && site.isHeadOffice === true
  );
  return !!foundSite;
};

export function updateNestedItem(data, updatedItem) {
  return data.map((item) => {
    if (item?.id == updatedItem?.id) {
      // If the current item matches the updated item, return the updated item
      return updatedItem;
    } else if (item.childList && item.childList.length > 0) {
      // If the current item has a childList, recursively update the childList
      return {
        ...item,
        childList: updateNestedItem(item.childList, updatedItem)
      };
    } else {
      // If no match is found, return the current item unchanged
      return item;
    }
  });
}